import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export type DatePickerFieldProps = {
  label?: string;
  fromDate?: Date;
  toDate?: Date;
  onFromDate: (date: Date) => void;
  onToDate: (date: Date) => void;
};

export function DatePickerField({
  label,
  fromDate,
  toDate,
  onFromDate,
  onToDate,
}: DatePickerFieldProps) {
  return (
    <div className="flex-col text-center">
      <h6 className="text-[#78909C] text-[12px] flex justify-start">{label}</h6>
      <div className="flex w-min items-center">
        <div className="flex items-center">
          <DatePicker
            selected={fromDate}
            dateFormat="MM/dd/yyyy"
            maxDate={toDate}
            placeholderText="mm/dd/yyyy"
            className="w-[100px] border p-2 focus:border-primary-600 focus:outline-none border-[#B0BEC5] rounded-lg text-sm text-[#455A64]"
            onChange={onFromDate}
          />
        </div>
        <h4 className="px-2">to</h4>
        <DatePicker
          selected={toDate}
          placeholderText="mm/dd/yyyy"
          dateFormat="MM/dd/yyyy"
          className="w-[100px] border p-2 focus:border-primary-600 focus:outline-none border-[#B0BEC5] rounded-lg text-sm text-[#455A64]"
          onChange={(value) => {
            if (value !== null) {
              if (
                fromDate !== undefined &&
                toDate !== undefined &&
                fromDate > value
              ) {
                onFromDate(value);
              }

              onToDate(value);
            }
          }}
        />
      </div>
    </div>
  );
}
