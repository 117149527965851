/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { AppDispatch } from '../types';
import { ResponseList, ResponseOne } from '../helpers/axiosResponse';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';

export type ChapterModel = {
  id: number;
  name: string;
  order: number;
};

const initialState: InitialState<ChapterModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'chapters',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export type ChapterBody = {
  name: string;
  order?: number;
};

export const createChapter =
  (
    body: ChapterBody,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      await api.post<ResponseList>('/chapters', body);
      dispatch(onSuccess());
      if (handleSuccess)
        handleSuccess('Chapter has been successfully created.');
    } catch (error) {
      if (handleError) handleError('Failed to create chapter.');
      dispatch(onSuccess());
    }
  };

export const findAllChapters = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());
    const response = await api.get<ResponseList>('/chapters');
    const responseData = response.data.body.data;

    const chapters: ChapterModel[] = responseData.result.map<ChapterModel>(
      (data) => ({
        id: data.id,
        name: data.name,
        order: data.order,
      }),
    );

    dispatch(onSuccess());
    dispatch(setData(chapters));
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

// eslint-disable-next-line consistent-return
export const findOneChapter = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());
    const response = await api.get<ResponseOne>(`/chapters/${id}`);
    const responseData = response.data.body.data;

    const chapter: ChapterModel = {
      id: responseData.id,
      name: responseData.name,
      order: responseData.order,
    };

    dispatch(onSuccess());
    dispatch(setData(chapter));
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const updateChapter =
  (
    id: number,
    body: ChapterBody,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.put<ResponseList>(`/chapters/${id}`, body);

      dispatch(onSuccess());
      if (handleSuccess)
        handleSuccess('Chapter has been successfully updated.');
    } catch (error) {
      if (handleError) handleError('Failed to update chapter.');
      dispatch(onSuccess());
    }
  };

export const deleteChapter =
  (
    id: number,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.delete<ResponseList>(`/chapters/${id}`);

      dispatch(findAllChapters());
      dispatch(onSuccess());

      if (handleSuccess)
        handleSuccess('Chapter has been successfully deleted.');
    } catch (error) {
      if (handleError) handleError('Failed to update chapter.');
      dispatch(onSuccess());
    }
  };

export const reorderChapter =
  (
    id: number,
    newPosition: number,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // dispatch(onLoading());
      await api.patch<ResponseList>(`/chapters/${id}/reorder/${newPosition}`);

      dispatch(findAllChapters());
      dispatch(onSuccess());

      if (handleSuccess)
        handleSuccess('Chapter has been successfully reordered.');
    } catch (error) {
      if (handleError) handleError('Failed to reorder chapter.');
      dispatch(onSuccess());
      // dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
