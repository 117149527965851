/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../../app/config/api';
// import { api } from '../authReducer';
import { AppDispatch } from '../../types';
import { ErrorMessage } from '../../../app/constants/error_messages';
import { InitialState, StateStatus } from '../../helpers/initialState';

export type UserPlanFilter = {
  sortBy?: string;
  orderBy?: string;
  take?: number;
  page?: number;
  firstName?: string;
  lastName?: string;
  planId?: number;
};

export type UserPlanTableModel = {
  userPlanId: number;
  firstName: string;
  lastName: string;
  email: string;
  purshasedAt: string;
  price: number;
  plan: string;
};

export type Pagination = {
  page: number;
  perPage: number;
  totalPages: number;
  totalItems: number;
};

export type UsersPlansTable = {
  pagination: Pagination;
  data: UserPlanTableModel[];
};

const initialState: InitialState<UsersPlansTable> = {
  status: StateStatus.INITIAL,
  data: {
    data: [],
    pagination: {
      page: 0,
      perPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  errorMessage: '',
};

const slice = createSlice({
  name: 'users-plans',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllUsersPlansTable =
  ({
    sortBy,
    orderBy,
    page,
    take = 10,
    firstName,
    lastName,
    planId,
  }: UserPlanFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      const firstNameString = firstName ? `first_name=${firstName}` : '';
      const lastNameString = lastName ? `last_name=${lastName}` : '';
      const planIdString = planId ? `plan=${planId}` : '';
      const sortByString = sortBy ? `sortBy=${sortBy}` : '';
      const orderByString = orderBy ? `orderBy=${orderBy}` : '';

      const response = await api.get(
        `/users-plans/admin/table?take=${take}&page=${page}&${firstNameString}&${lastNameString}&${planIdString}&${sortByString}&${orderByString}`,
      );

      const responseData: any = response.data.body.data;

      const usersTable: UsersPlansTable = {
        pagination: {
          page: responseData.pagination.page,
          perPage: responseData.pagination.per_page,
          totalItems: responseData.pagination.total_items,
          totalPages: responseData.pagination.total_pages,
        },
        data:
          responseData.data.map((data: any) => ({
            userPlanId: data.user_plan_id,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            purchasedAt: data.purchased_at,
            price: data.price,
            plan: data.plan,
            planStatus: data.planStatus,
          })) ?? [],
      };

      dispatch(onSuccess());
      dispatch(setData(usersTable));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
