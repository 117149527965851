/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { adminLogin } from '../../../store/reducers/authReducer';
import { RootState } from '../../../store/types';
import { AnimatedPage } from '../../components/AnimatedPage';
import { FormInputLoadingButton } from '../../components/Button';
import { InputTextField } from '../../components/InputTextField';

export const Login = () => {
  const { loading } = useSelector((state: RootState) => state.auth);
  const dispatch: any = useDispatch(); // eslint-disable-line

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [cookie, setCookie, removeCookie] = useCookies([
    'lsToken',
    'lsRefreshToken',
  ]);

  const handleSuccess = () => {
    // toast.success('You have logged in successfully!', {
    //   position: 'bottom-center',
    //   autoClose: 5000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
  };

  const handleError = () => {
    setPassword('');
    toast.error('Incorrect email or password!', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(adminLogin(email, password, handleSuccess, handleError));
  };

  return (
    <AnimatedPage>
      <div className="flex fixed top-0 left-0 right-0 w-screen h-screen bg-primary-800">
        <div className="flex flex-col justify-center m-auto bg-white w-[400px] h-[400px] rounded-xl p-10">
          <h1 className="text-center font-bold text-primary-600 pb-5">Admin</h1>

          <form
            className="flex flex-col grow justify-center"
            onSubmit={onSubmit}
          >
            <InputTextField
              label="Email"
              placeholder="Your email"
              value={email}
              isRequired
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="h-4" />
            <InputTextField
              label="Password"
              placeholder="Your password"
              value={password}
              isRequired
              isPassword
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="h-8" />
            <FormInputLoadingButton
              title={loading ? 'Loading...' : 'Login'}
              isLoading={loading}
            />
          </form>
        </div>
      </div>
    </AnimatedPage>
  );
};
