/* eslint-disable @typescript-eslint/no-explicit-any */

import * as MaterialTailwind from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { findAllPlans } from '../../../../store/reducers/plansReducer';
import { findAllUsersPlansTable } from '../../../../store/reducers/tables/usersPlansTableReducer';
import { RootState } from '../../../../store/types';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { customStyles } from '../../../components/DataTableStyle';
import { makeFinancesTableColumns } from './FinancesTableColumns';

const { Input, Option, Select } = MaterialTailwind as any;

export function Finances() {
  const [filterByFirstName, setFilterByFirstName] = useState('');
  const [filterByLastName, setFilterByLastName] = useState('');
  const [filterByPlan, setFilterByPlan] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch: any = useDispatch();

  const plans = useSelector((state: RootState) => state.plans.data);
  const state = useSelector((state: RootState) => state.usersPlansTable);

  useEffect(() => {
    dispatch(findAllPlans());
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        findAllUsersPlansTable({
          take: perPage,
          page: currentPage,
          firstName: filterByFirstName,
          lastName: filterByLastName,
          planId: Number(filterByPlan),
        }),
      );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [perPage, currentPage, filterByFirstName, filterByLastName, filterByPlan]);

  return (
    <AnimatedPage>
      <div className="border rounded-md">
        <DataTable
          title="Finances"
          columns={makeFinancesTableColumns()}
          data={state.data.data}
          pagination
          responsive
          highlightOnHover
          striped
          paginationServer
          paginationRowsPerPageOptions={[10, 15, 20, 30]}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPage={perPage}
          onChangeRowsPerPage={(currentPerPage) => setPerPage(currentPerPage)}
          paginationTotalRows={state.data.pagination.totalItems}
          subHeader
          subHeaderComponent={[
            <div className="flex flex-wrap justify-end items-end gap-3 mr-4">
              <div className="w-full lg:w-[200px]">
                <Input
                  label="First name"
                  color="teal"
                  width="200px"
                  onChange={(value: any) =>
                    setFilterByFirstName(value.target.value.trim())
                  }
                />
              </div>
              <div className="w-full lg:w-[200px]">
                <Input
                  label="Last name"
                  color="teal"
                  width="200px"
                  onChange={(value: any) =>
                    setFilterByLastName(value.target.value.trim())
                  }
                />
              </div>
              <div className="w-full lg:w-[200px]">
                <Select
                  label="Select plan"
                  size="md"
                  color="teal"
                  onChange={(value: any) => setFilterByPlan(String(value))}
                >
                  {plans.map((plan) => (
                    <Option key={String(plan.id)} value={String(plan.id)}>
                      {plan.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>,
          ]}
          customStyles={customStyles}
        />
      </div>
    </AnimatedPage>
  );
}
