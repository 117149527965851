export function maskToUSCurrency(money: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(money);
}

export function removeMaskedMoney(moneyMasked: string): number {
  const rawMoney: string = moneyMasked.replace(/\D+/g, '');

  return Number(rawMoney) / 100;
}
