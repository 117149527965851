import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { AppDispatch } from '../types';

export interface IBookState {
  setBooks: boolean;
  loading?: boolean;
}

export interface IBook {
  id: string;
  title: string;
  subtitle: string;
  image: string;
  user: string;
  template: string;
}

export interface IBooksState extends InitialState<IBook | null> {
  bookList: IBook[];
  count: number;
  loading: boolean;
  // filters: IUsersFilter;
}

export interface IResponseFindAllBooks {
  result: IBook[];
  count: number;
}

export const initialState: IBooksState = {
  loading: false,
  status: StateStatus.INITIAL,
  data: null,
  errorMessage: '',
  bookList: [],
  count: 0,
};

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
      state.errorMessage = '';
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
    setBooks: (state, action: PayloadAction<IResponseFindAllBooks>) => {
      state.bookList = action.payload.result;
      state.count = action.payload.count;
    },
    startLoading: (state) => {
      state.status = StateStatus.LOADING;
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setData,
  onSuccess,
  onError,
  setBooks,
  startLoading,
  stopLoading,
} = bookSlice.actions;

export default bookSlice.reducer;

// Actions
export const getBooksByUser =
  (userId: string) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const result = await api.get(`/users-books/find-by-user/${userId}`);

      const response: IResponseFindAllBooks = result.data?.body?.data;
      // if (!response) throw new Error('An error has occurred');
      dispatch(setBooks(response));
    } catch (error) {
      // error handling goes down here...
      // if (error instanceof AxiosError) console.error(error.message);
    } finally {
      dispatch(stopLoading());
    }
  };

export const getBookByUser =
  (userId: string) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const result = await api.get(`/users-books/user/${userId}`);

      const response: IBook = result.data?.body?.data;
      // if (!response) throw new Error('An error has occurred');
      dispatch(onSuccess());
      dispatch(setData(response));
    } catch (error) {
      // error handling goes down here...
      // if (error instanceof AxiosError) console.error(error.message);
      dispatch(onError(ErrorMessage.UNEXPECTED));
      dispatch(setData(null));
    } finally {
      dispatch(stopLoading());
    }
  };
