// 01/30/2022, 02:05 PM
export const dateTimeFormat = (dateTime: Date): string =>
  new Date(dateTime).toLocaleString('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

export const dateFormat = (date: Date): string => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
};

// [01/30]
export const formatDatesToMMDD = (dates: Date[]): string[] =>
  dates.map((day) =>
    new Date(day).toLocaleString('en-us', {
      month: '2-digit',
      day: '2-digit',
    }),
  );
