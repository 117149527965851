/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from 'react';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import * as MaterialTailwind from '@material-tailwind/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';

import { RootState } from '../../../../../store/types';
import { DatePickerField } from '../../../../components/filters/DatePickerField';
import { findAllContributionsGraph } from '../../../../../store/reducers/graphs/contributionsReducer';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);
const { Input, Option, Select } = MaterialTailwind as any;

export function ContributionsChart() {
  const [filterByLastDays, setFilterByLastDays] = useState('7');

  const [filterToDate, setFilterToDate] = useState<Date>();
  const [filterFromDate, setFilterFromDate] = useState<Date>();

  const [hasCustom, setHasCustom] = useState(false);

  const [menuOptionSelected, setMenuOptionSelected] = useState('1');

  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state.graphContributions);

  const handleChangeMenuOption = (option: string) => {
    if (option === '1') {
      setFilterByLastDays('7');

      setFilterToDate(undefined);
      setFilterFromDate(undefined);
    }

    if (option === '2') {
      const toDate = new Date();
      const fromDate = new Date();

      fromDate.setDate(fromDate.getDate() - 6);

      setFilterToDate(toDate);
      setFilterFromDate(fromDate);

      setFilterByLastDays('');
    }

    setMenuOptionSelected(option);
  };

  useEffect(() => {
    dispatch(
      findAllContributionsGraph({
        days: filterByLastDays,
        toDate: filterToDate,
        fromDate: filterFromDate,
      }),
    );
  }, [filterByLastDays, filterFromDate, filterToDate]);

  const filterSelected = () =>
    menuOptionSelected === '1' ? (
      <>
        <div className="w-full lg:w-[200px]">
          <Select
            label="Select last days"
            size="md"
            color="teal"
            value={hasCustom ? 'custom' : filterByLastDays}
            onChange={(value: any) => {
              if (value === 'custom') {
                setHasCustom(true);
                return;
              }

              setHasCustom(false);
              setFilterByLastDays(String(value));
            }}
          >
            <Option value="7">Last 7 days</Option>
            <Option value="15">Last 15 days</Option>
            <Option value="30">Last 30 days</Option>
            <Option value="custom">Custom</Option>
          </Select>
        </div>
        {hasCustom ? (
          <div className="w-full lg:w-[200px]">
            <Input
              color="teal"
              type="number"
              label="Custom days"
              value={filterByLastDays}
              onChange={(value: any) => {
                const valueNumber = Number(value.target.value);
                if (valueNumber < 1) return;
                setFilterByLastDays(String(value.target.value));
              }}
            />
          </div>
        ) : (
          <div />
        )}
      </>
    ) : (
      <DatePickerField
        onFromDate={(value) => {
          setFilterFromDate(value);
          setFilterByLastDays('');
        }}
        onToDate={(value) => {
          setFilterToDate(value);
          setFilterByLastDays('');
        }}
        fromDate={filterFromDate}
        toDate={filterToDate}
      />
    );

  return (
    <div className="rounded-md w-full lg:w-[70%]">
      <div className="flex gap-2 flex-wrap">
        <div className="w-full lg:w-[200px]">
          <Select
            label="Filter options"
            size="md"
            color="teal"
            variant="standard"
            value={menuOptionSelected}
            onChange={(value: any) => handleChangeMenuOption(String(value))}
          >
            <Option value="1">Last days</Option>
            <Option value="2">Date range</Option>
          </Select>
        </div>
        {filterSelected()}
      </div>
      <div className="flex w-full items-center">
        <Line
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'User contributions',
              },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
            },
          }}
          data={{
            datasets: [
              {
                label: 'Contributions',
                tension: 0,
                data: state.data,
                borderColor: '#BB8FCE',
                backgroundColor: '#BB8FCE',
              },
            ],
          }}
        />
      </div>
    </div>
  );
}
