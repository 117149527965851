/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '../types';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { ResponseListNoPagination } from '../helpers/axiosResponse';
import { UserModel } from './usersReducer';
import { IBook } from './bookReducer';

export type BookReadersFilter = {
  userId?: string;
};

export type BookReaderModel = {
  id: number;
  name: string;
  phone?: string;
  email: string;
  usersBooks: IBook[];
  user: UserModel;
  createdAt: Date;
  updatedAt: Date;
};

const initialState: InitialState<BookReaderModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'bookReaders',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
      state.errorMessage = '';
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllBookReadersByUser =
  ({ userId }: BookReadersFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      const response = await api.get<ResponseListNoPagination>(
        `/book-readers/user/${userId}`,
      );
      const responseData = response.data.body.data;

      let bookReaders: BookReaderModel[] = [];

      if (responseData.length !== 0) {
        bookReaders = responseData.map<BookReaderModel>((data) => ({
          id: data.id,
          name: data.name,
          phone: data.phone,
          email: data.email,
          usersBooks: data.users_books,
          user: data.user,
          createdAt: data.created_at,
          updatedAt: data.updated_at,
        }));
      }

      dispatch(onSuccess());
      dispatch(setData(bookReaders));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
