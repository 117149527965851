/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';

import { dateTimeFormat } from '../../../utils/date-time-formatter';
import { UserOrdersModel } from '../../../../store/reducers/userOrdersReducer';
import {
  OrderStatusDTO,
  OrderStatusEnum,
} from '../../../../repositories/orderStatusRepository';

export type BookOrdersTableColumns = {
  allOrderStatus: OrderStatusDTO[];
  onClick: (userOrdersModel: UserOrdersModel) => void;
  onOverrideStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: number,
  ) => Promise<void>;
  updateTrackingInfo: (id: number) => void;
};

export function makeBookOrdersTableColumns({
  allOrderStatus,
  onClick,
  onOverrideStatus,
  updateTrackingInfo: sweetAlert,
}: BookOrdersTableColumns) {
  return [
    {
      name: '',
      sortable: true,
      width: '100px',
      selector: (row: any) => row.details,
      cell: (row: any) => (
        <button
          className="py-2 px-3 rounded-md bg-primary-500 hover:bg-primary-600  text-xs text-white font-bold"
          onClick={() => onClick(row)}
          id={row.action}
        >
          Details
        </button>
      ),
    },
    {
      name: 'BOOK ORDER ID',
      sortable: true,
      selector: (row: any) => row.id,
    },
    {
      name: 'FIRST NAME',
      sortable: true,
      wrap: true,
      selector: (row: any) => row.user.firstName,
    },
    {
      name: 'LAST NAME',
      sortable: true,
      wrap: true,
      selector: (row: any) => row.user.lastName,
    },
    {
      name: 'STATUS',
      sortable: true,
      wrap: true,
      width: '260px',
      cell: (row: any) => (
        <>
          <select
            className="m-0 p-2 h-9 border border-[#555454] rounded cursor-pointer "
            onChange={(e) => onOverrideStatus(e, row.id)}
            value={row.status.id}
          >
            {allOrderStatus.map((orderStatus) => (
              <option key={orderStatus.id} value={orderStatus.id}>
                {orderStatus.status}
              </option>
            ))}
          </select>
          {[OrderStatusEnum.DELIVERED, OrderStatusEnum.SHIPPING].includes(
            row.status.status,
          ) ? (
            <button
              onClick={() => sweetAlert(row.id)}
              className="ml-3 py-2 px-3 rounded-md bg-btnEdit hover:bg-btnEditOnHover text-xs text-white font-bold"
            >
              {!row.trackingUrl ? 'ADD INFO' : 'UPDATE INFO'}
            </button>
          ) : null}
        </>
      ),
    },
    {
      name: 'TOTAL PRICE',
      sortable: true,
      wrap: true,
      selector: (row: any) =>
        (row.total / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      name: 'ORDER DATE',
      sortable: true,
      wrap: true,
      selector: (row: any) => row.createdAt,
      format: (row: any) => dateTimeFormat(row.createdAt),
    },
  ];
}
