import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { BaseLayout } from '../components/BaseLayout';
import { PageNotFound } from '../pages/PageNotFound';
import { AdminRouter } from './AdminRouter';
import templateConfig from '../config/template.config.json';

export const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = `Admin ┃ ${templateConfig.primaryTitle}`;
  }, []);

  return (
    <AnimatePresence>
      <BaseLayout
        location={location}
        page={
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<AdminRouter />} />
            <Route path="admin/*" element={<AdminRouter />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        }
      />
    </AnimatePresence>
  );
};
