/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { CategoryModel } from '../../../../store/reducers/categoryReducer';

export type PlansTableColumns = {
  onEdit: (plan: CategoryModel) => void;
  onDelete: (id: number) => void;
};

export function makeCategoryTableColumns({
  onEdit,
  onDelete,
}: PlansTableColumns) {
  return [
    {
      name: 'ID',
      sortable: true,
      // width: '80px',
      selector: (row: any, index: any) => index + 1,
    },
    {
      name: 'CATEGORY NAME',
      sortable: true,
      // width: '20rem',
      // grow: 1,
      selector: (row: any) => row.title,
      cell: (row: any) => <div>{row.title}</div>,
    },
    {
      name: 'ACTIONS',
      wrap: true,

      width: '250px',
      selector: (row: any) => row.action,
      cell: (row: any) => (
        <div className="flex">
          <div className="w-2" />
          <button
            className="py-2 px-3 rounded-md bg-btnEdit hover:bg-btnEditOnHover  text-xs text-white font-bold"
            onClick={() =>
              onEdit({
                id: row.id,
                title: row.title,
              })
            }
            id={row.action}
          >
            Edit
          </button>
          <div className="w-2" />
          <button
            className="py-2 px-3 rounded-md bg-btnDelete hover:bg-btnDeleteOnHover text-xs text-white font-bold"
            onClick={() => onDelete(row.id)}
            id={row.action}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
}
