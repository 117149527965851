/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../app/config/api';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { AppDispatch } from '../types';
// import { api } from './authReducer';
import { ResponseList } from '../helpers/axiosResponse';

export type UserOrdersFilter = {
  id?: number;
  firstName?: string;
  lastName?: string;
  paymentFromDate?: Date;
  paymentToDate?: Date;
};

type OrderStatus = {
  id: number;
  status: string;
};

type UserModel = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  whyWriting: string;
  city: string;
  zip: string;
  state: string;
  phone: string;
  profilePicture: string;
  authorType: string;
  otherFirstName: string;
  otherLastName: string;
  otherEmail: string;
  freeEndsAt: string;
  createdAt: Date;
  updatedAt: Date;
};

type UserAddress = {
  id: number;
  name: string;
  country: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip_code: string;
};

export type UserBookModel = {
  id: number;
  title: string;
  subtitle: string;
  image: string;
  author: string;
  backgroundColor: string;
  textColor: string;
  created_at: string;
  updated_at: string;
};

export type UserOrdersModel = {
  id: number;
  user: UserModel;
  user_address: UserAddress;
  status: OrderStatus;
  quantity: number;
  total: number;
  price_per_book: number;
  estimated_shipping_cost: number;
  bookUrl: string;
  paymentId: string;
  shippingMethod: string;
  shippingInfo: string;
  shippingCost: string;
  trackingUrl?: string;
  trackingNumber?: number;
  orderAt: string;
  createdAt: Date;
  updatedAt: Date;
  users_books: UserBookModel;
};

const initialState: InitialState<UserOrdersModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'userOrders',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllUserOrders =
  ({
    id,
    firstName,
    lastName,
    paymentFromDate,
    paymentToDate,
  }: UserOrdersFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      const response = await api.get<ResponseList>(
        `/users-orders?${firstName ? `firstName=${firstName}` : ''}&${
          lastName ? `lastName=${lastName}` : ''
        }&${paymentFromDate ? `paymentFromDate=${paymentFromDate}` : ''}&${
          paymentToDate ? `paymentToDate=${paymentToDate}` : ''
        }&${id ? `id=${id}` : ''}`,
      );
      const responseData = response.data.body.data;

      let userOrders: UserOrdersModel[] = [];
      if (responseData.result.length !== 0) {
        userOrders = responseData.result.map<UserOrdersModel>((data) => ({
          id: data.id,
          user: {
            id: data.user.id ?? 0,
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
            whyWriting: data.user.why_writing,
            city: data.user.city,
            zip: data.user.zip,
            state: data.user.state,
            phone: data.user.phone,
            profilePicture: data.user.profile_picture,
            authorType: data.user.author_type,
            otherFirstName: data.user.other_first_name,
            otherLastName: data.user.other_last_name,
            otherEmail: data.user.other_email,
            freeEndsAt: data.user.free_ends_at,
            createdAt: data.user.created_at,
            updatedAt: data.user.updated_at,
          },
          users_books: {
            id: data.users_books.id,
            title: data.users_books.title,
            subtitle: data.users_books.subtitle,
            image: data.users_books.image,
            author: data.users_books.author,
            backgroundColor: data.users_books.backgroundColor,
            textColor: data.users_books.textColor,
            created_at: data.users_books.created_at,
            updated_at: data.users_books.updated_at,
          },
          status: {
            id: data.order_status.id,
            status: data.order_status.status,
          },
          user_address: {
            id: data.user_address.id ?? 0,
            name: data.name,
            address_line1: data.address_line1,
            address_line2: data.address_line2,
            city: data.city,
            country: data.country,
            state: data.state,
            zip_code: data.zip_code,
          },
          orderAt: data.order_at,
          quantity: data.quantity,
          price_per_book: data.price_per_book,
          estimated_shipping_cost: data.estimated_shipping_cost,
          shippingMethod: data.shipping_method,
          shippingInfo: data.shipping_info,
          bookUrl: data.bookUrl,
          trackingUrl: data?.trackingUrl,
          trackingNumber: data?.trackingNumber,
          shippingCost: data.shipping_cost,
          createdAt: data.created_at,
          updatedAt: data.updated_at,
          total: data.total,
          paymentId: data.paymentId,
        }));
      }

      dispatch(onSuccess());
      dispatch(
        setData(
          userOrders.filter((item) => item.status.status !== 'processing'),
        ),
      );
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const changeUserOrderStatus =
  (id: number, status: number) => async (dispatch: AppDispatch) => {
    await api.patch<ResponseList>(`/users-orders/${id}/status/${status}`);
    dispatch(findAllUserOrders({}));
    dispatch(onSuccess());
  };

export const changeUserOrderTrackingUrl =
  (id: number, trackingUrl: string, trackingNumber: number) =>
  async (dispatch: AppDispatch) => {
    const body = { trackingUrl: trackingUrl, trackingNumber: trackingNumber };

    await api.patch<ResponseList>(`/users-orders/${id}/tracking-url`, body);

    dispatch(findAllUserOrders({}));

    dispatch(onSuccess());
  };
