/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import * as MaterialTailwind from '@material-tailwind/react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { AnimatedPage } from '../../../components/AnimatedPage';
import { FormInputLoadingButton } from '../../../components/Button';
import {
  CategoryModel,
  createCategory,
  updateCategory,
} from '../../../../store/reducers/categoryReducer';

const { Input } = MaterialTailwind as any;
export function CategoryForm() {
  const [id, setId] = useState<number>();
  const [title, setTitle] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (location.state !== null) {
      const category = location.state as CategoryModel;
      setId(category.id);

      setTitle(category.title);
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (action === 'create') {
      await dispatch(
        createCategory({
          title,
        }),
      );
    } else {
      await dispatch(
        updateCategory(id!, {
          title,
        }),
      );
    }

    navigate(-1);
  };

  return (
    <AnimatedPage>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="flex flex-wrap items-center">
          <Link
            to="/admin/faq"
            // className="w-fit flex space-x-2 items-center justify-between h-9 px-4 bg-primary-500 rounded-full hover:bg-primary-600 text-white text-h4"
          >
            <IoArrowBackSharp size={30} />
          </Link>
          <h2 className="ml-8">
            {action === 'create'
              ? 'Create a new Category'
              : 'Edit your Category'}
          </h2>
        </div>
        <div className="mt-4 sm:w-full md:w-[70%] xl:w-[50%]">
          <div className="block xl:flex xl:flex-row">
            <div className="xl:w-full">
              <Input
                label="Title"
                color="teal"
                size="lg"
                variant="standard"
                value={title}
                required
                className="border-primary-500 hover:border-primary-600 w-full"
                onChange={(e: any) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="h-4 sm:h-4 xl:h-5 xl:w-8" />
        </div>
        <div className="h-8" />
        <FormInputLoadingButton
          title={action === 'create' ? 'Create' : 'Edit'}
          titleWhenLoading="Creating"
          isLoading={false}
        />
      </form>
    </AnimatedPage>
  );
}
