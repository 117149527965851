/* eslint-disable @typescript-eslint/no-empty-function */

import Swal from 'sweetalert2';
import { AxiosError } from 'axios';
import * as MaterialTailwind from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';

import { AnimatedPage } from '../../../components/AnimatedPage';
import { FormInputLoadingButton } from '../../../components/Button';
import { GeneralRepository } from '../../../../repositories/generalRepository';

const { Input } = MaterialTailwind as any;

export function General() {
  const generalRepository = new GeneralRepository();

  const [linkUrl, setLinkUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    generalRepository.find().then((value) => setLinkUrl(value.welcomeVideoUrl));
  }, []);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await generalRepository.create({ welcomeVideoUrl: linkUrl });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.data.body.errors ===
          'welcomeVideoUrl must be a valid youtube url'
        )
          Swal.fire({
            title: 'An error has occurred.',
            text: 'The url must be a youtube video url',
            icon: 'error',
            confirmButtonText: 'Ok!',
            confirmButtonColor: '#fa4848',
          });
      } else {
        Swal.fire({
          title: 'An error has occurred.',
          text: 'Try again!',
          icon: 'error',
          confirmButtonText: 'Ok!',
          confirmButtonColor: '#fa4848',
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <AnimatedPage>
      <>
        <h1>General</h1>
        <div className="h-6" />
        <h4>Welcome video - media link</h4>
        <div className="h-6" />
        <form onSubmit={submit}>
          <div className="w-[600px]">
            <Input
              required
              size="lg"
              type="url"
              color="teal"
              value={linkUrl}
              label="Link here"
              variant="standard"
              onChange={(e: any) => setLinkUrl(e.target.value)}
              className="border-primary-500 hover:border-primary-600 w-full"
            />
          </div>
          <div className="h-32" />
          <FormInputLoadingButton
            title="Save changes"
            isLoading={isLoading}
            isDisable={linkUrl === ''}
            titleWhenLoading="Saving..."
          />
        </form>
      </>
    </AnimatedPage>
  );
}
