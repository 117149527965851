/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import * as MaterialTailwind from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal } from '../../../components/Modal';
import { RootState } from '../../../../store/types';
import { LinkButton } from '../../../components/Button';
import { makeFaqTableColumns } from './FaqTableColumns';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { customStyles } from '../../../components/DataTableStyle';
import { StateStatus } from '../../../../store/helpers/initialState';
import {
  deleteFaq,
  FaqModel,
  findAllFaq,
} from '../../../../store/reducers/faqReducer';
import { FilterComponent } from '../../../components/FilterComponent';
import { makeCategoryTableColumns } from './CategoryTableColumns';
import {
  CategoryModel,
  deleteCategory,
  findAllCategory,
} from '../../../../store/reducers/categoryReducer';

const { Checkbox, Chip } = MaterialTailwind as any;
export function Faq() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [faqOrCategoryData, setFaqOrCategoryData] = useState<any>();
  const [removeItemID, setRemoveItemID] = useState<number>();
  const [filterByQuestion, setFilterByQuestion] = useState('');
  const [filterByAnswer, setFilterByAnswer] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isReorder, setReorder] = useState(false);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const faqState = useSelector((state: RootState) => state.faq);
  const categoryState = useSelector((state: RootState) => state.category);

  useEffect(() => {
    dispatch(findAllFaq());
    dispatch(findAllCategory());
  }, [findAllFaq, findAllCategory]);

  const filteredItems = faqState.data.filter(
    (item) =>
      item.question &&
      item.question.toLowerCase().includes(filterByQuestion.toLowerCase()),
  );

  const resultFilteredItems = filteredItems.filter(
    (item) =>
      item.answer &&
      item.answer.toLowerCase().includes(filterByAnswer.toLowerCase()),
  );

  useEffect(() => {
    setFaqOrCategoryData(resultFilteredItems);
  }, [filterByAnswer, filterByQuestion]);

  useEffect(() => {
    if (isCategory) {
      setFaqOrCategoryData(categoryState.data);
    } else {
      setFaqOrCategoryData(faqState.data);
    }

    // setFaqOrCategoryData(
    //   faqOrCategoryData.sort((a: any, b: any) => a.created_at > b.created_at),
    // );
  }, [isCategory, faqState, categoryState]);

  const handleClear = (label: string) => {
    setResetPaginationToggle(!resetPaginationToggle);
    switch (label) {
      case 'question':
        setFilterByQuestion('');
        break;

      case 'answer':
        setFilterByAnswer('');
        break;
      default:
        setFilterByQuestion('');
        setFilterByAnswer('');
        break;
    }
  };

  const onRemoveItem = () => {
    if (removeItemID !== undefined) dispatch(deleteFaq(removeItemID));
    setModalOpen(false);
  };

  const removeCategory = async (id: number) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    try {
      if (result.isConfirmed) {
        await dispatch(deleteCategory(id));
        Swal.fire({
          title: 'Removed!',
          text: 'Category has been removed successfully.',
          icon: 'success',
          confirmButtonText: 'Great!',
          confirmButtonColor: '#5CACA8',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'An error has occurred.',
        text: 'Category has a link with FAQ!',
        icon: 'error',
        confirmButtonText: 'Ok!',
        confirmButtonColor: '#fa4848',
      });
    }
  };

  return (
    <AnimatedPage>
      <>
        <div className="border rounded-md">
          <Modal
            isOpen={isModalOpen}
            setOpen={setModalOpen}
            onDone={onRemoveItem}
            title="Are you sure you want to delete it?"
          >
            <p className="pt-4">The data will be permanently deleted.</p>
            <p>This action is irreversible.</p>
          </Modal>
        </div>
        <DataTable
          title={
            <>
              <div className="h-4" />

              <label
                htmlFor="Toggle3"
                className="inline-flex items-center p-2 rounded-md cursor-pointer"
              >
                <input
                  onChange={() => setIsCategory(!isCategory)}
                  id="Toggle3"
                  type="checkbox"
                  className="hidden peer"
                />
                <span className="px-4 py-2 rounded-l-md bg-primary-500 peer-checked:bg-primary-100">
                  FAQ
                </span>
                <span className="px-4 py-2 rounded-r-md bg-primary-100 peer-checked:bg-primary-500">
                  Category
                </span>
              </label>

              {isCategory ?? faqState.status === StateStatus.LOADING ? (
                <div />
              ) : (
                <CSVLink
                  data={faqState.data}
                  filename={`faq-${new Date().toISOString()}.csv`}
                >
                  <Chip value="download table" color="indigo" />
                </CSVLink>
              )}
              <div className="h-1" />
            </>
          }
          columns={
            !isCategory
              ? makeFaqTableColumns({
                  onEdit: (faq: FaqModel) => {
                    navigate('/admin/faq/form/edit', {
                      state: {
                        id: faq.id,
                        question: faq.question,
                        answer: faq.answer,
                        mediaLink: faq.mediaLink,
                        category: faq.category,
                        mediaLinkType: faq.mediaLinkType,
                      },
                    });
                  },
                  onDelete: (id) => {
                    setRemoveItemID(id);
                    setModalOpen(true);
                  },
                  length: faqState.data.length,
                  isReorder,
                })
              : makeCategoryTableColumns({
                  onEdit: (category: CategoryModel) => {
                    navigate('/admin/category/form/edit', {
                      state: {
                        id: category.id,
                        title: category.title,
                      },
                    });
                  },
                  onDelete: (id) => {
                    removeCategory(id);
                  },
                })
          }
          data={faqOrCategoryData}
          pagination
          responsive
          highlightOnHover
          striped
          subHeader
          subHeaderComponent={
            isCategory ?? faqState.data.length === 0 ? null : (
              <div className="flex gap-2 ml-4">
                <FilterComponent
                  label="Filter by question"
                  onFilter={(e) => setFilterByQuestion(e.target.value)}
                  onClear={() => handleClear('question')}
                  filterText={filterByQuestion}
                />
                <FilterComponent
                  label="Filter by answer"
                  onFilter={(e) => setFilterByAnswer(e.target.value)}
                  onClear={() => handleClear('answer')}
                  filterText={filterByAnswer}
                />
                <Checkbox
                  // crossOrigin="true"
                  color="teal"
                  label="Reorder"
                  checked={isReorder}
                  onChange={() => {
                    setReorder(!isReorder);
                  }}
                />
              </div>
            )
          }
          progressPending={faqState.status === StateStatus.LOADING}
          actions={
            !isCategory ? (
              <LinkButton text="Create FAQ" route="/admin/faq/form/create" />
            ) : (
              <LinkButton
                text="Create Category"
                route="/admin/category/form/create"
              />
            )
          }
          customStyles={customStyles}
        />
      </>
    </AnimatedPage>
  );
}
