/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { PlanModel } from '../../../../store/reducers/plansReducer';
import { maskToUSCurrency } from '../../../utils/currency-formater';

export type PlansTableColumns = {
  onEdit: (plan: PlanModel) => void;
  onDelete: (id: number) => void;
};

export function makePlansTableColumns({ onEdit, onDelete }: PlansTableColumns) {
  return [
    {
      name: 'ID',
      sortable: true,
      width: '80px',
      selector: (row: any, index: any) => index + 1,
    },
    {
      name: 'NAME',
      sortable: true,
      width: '120px',
      wrap: true,
      selector: (row: any) => row.name,
    },
    {
      name: 'PRICE',
      sortable: true,
      width: '100px',
      selector: (row: any) => row.price,
      format: (row: any) => maskToUSCurrency(row.price / 100),
    },
    {
      name: 'PROMO ITEMS',
      sortable: true,
      minWidth: '200px',
      wrap: true,
      selector: (row: any) =>
        row.itemList.map((item: any) => (
          <>
            {item.promoItem}
            <br />
          </>
        )),
    },
    {
      name: 'DETAILED ITEMS',
      sortable: true,
      wrap: true,
      minWidth: '500px',
      selector: (row: any) =>
        row.itemList.map((item: any) => (
          <>
            {item.detailedItem}
            <br />
          </>
        )),
    },
    {
      name: 'ACTIONS',
      wrap: true,
      width: '150px',
      selector: (row: any) => row.action,
      cell: (row: any) => (
        <div className="flex">
          <button
            className="py-2 px-3 rounded-md bg-btnEdit hover:bg-btnEditOnHover  text-xs text-white font-bold"
            onClick={() =>
              onEdit({
                id: row.id,
                name: row.name,
                price: row.price,
                itemList: row.itemList,
              })
            }
            id={row.action}
          >
            Edit
          </button>
          <div className="w-2" />
          <button
            className="py-2 px-3 rounded-md bg-btnDelete hover:bg-btnDeleteOnHover text-xs text-white font-bold"
            onClick={() => onDelete(row.id)}
            id={row.action}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
}
