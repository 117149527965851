/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../../app/config/api';
// import { api } from '../authReducer';
import { AppDispatch } from '../../types';
import { ErrorMessage } from '../../../app/constants/error_messages';
import { InitialState, StateStatus } from '../../helpers/initialState';
import { ChapterModel } from '../chaptersReducer';

export type ChapterQuestionFilter = {
  take?: number;
  page?: number;
  name?: string;
  chapter?: string;
};

export type ChapterQuestionTableModel = {
  id: string;
  name: string;
  order: number;
  can_suggest: string;
  chapter?: ChapterModel;
};

export type Pagination = {
  page: number;
  perPage: number;
  totalPages: number;
  totalItems: number;
};

export type ChapterQuestionTable = {
  pagination: Pagination;
  data: ChapterQuestionTableModel[];
};

const initialState: InitialState<ChapterQuestionTable> = {
  status: StateStatus.INITIAL,
  data: {
    data: [],
    pagination: {
      page: 0,
      perPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  errorMessage: '',
};

const slice = createSlice({
  name: 'chapterQuestion',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllQuestionsTable =
  ({ page, name, chapter, take = 10 }: ChapterQuestionFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      const nameString = name ? `name=${name}` : '';
      const chapterString = chapter ? `chapter=${chapter}` : '';
      // const sortByString = sortBy ? `sortBy=${sortBy}` : '';
      // const orderByString = orderBy ? `orderBy=${orderBy}` : '';

      const response = await api.get(
        `/chapters-questions/admin/table?take=${take}&page=${page}&${nameString}&${chapterString}`,
      );
      const responseData: any = response.data.body.data;

      const usersTable: ChapterQuestionTable = {
        pagination: {
          page: responseData.pagination.page,
          perPage: responseData.pagination.per_page,
          totalItems: responseData.pagination.total_items,
          totalPages: responseData.pagination.total_pages,
        },
        data:
          responseData.data.map((data: any) => ({
            id: data.id,
            name: data.name,
            order: data.order,
            chapter: data.chapter,
            can_suggest: data.can_suggest,
          })) ?? [],
      };

      dispatch(onSuccess());
      dispatch(setData(usersTable));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
