/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import {
//   Button,
//   Chip,
//   Dialog,
//   DialogBody,
//   DialogFooter,
//   DialogHeader,
//   Input,
// } from '@material-tailwind/react';
import * as MaterialTailwind from '@material-tailwind/react';

import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GrFormClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {
  OrderStatusDTO,
  OrderStatusRepository,
} from '../../../../repositories/orderStatusRepository';
import { PDFRepository } from '../../../../repositories/pdfRepository';
import { StateStatus } from '../../../../store/helpers/initialState';
import { findAllOrderStatus } from '../../../../store/reducers/orderStatusReducer';
import {
  changeUserOrderStatus,
  changeUserOrderTrackingUrl,
  findAllUserOrders,
  UserOrdersModel,
} from '../../../../store/reducers/userOrdersReducer';
import { RootState } from '../../../../store/types';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { customStyles } from '../../../components/DataTableStyle';
import { DatePickerField } from '../../../components/filters/DatePickerField';
import { dateTimeFormat } from '../../../utils/date-time-formatter';
import { makeBookOrdersTableColumns } from './BookOrdersTableColumns';
import { CircleLoading } from '../../../components/loading/CircleLoading';

const { Button, Chip, Dialog, DialogBody, DialogFooter, DialogHeader, Input } =
  MaterialTailwind as any;

export function BookOrders() {
  const orderStatusRepository = new OrderStatusRepository();

  const [filterByBookId, setFilterByBookId] = useState('');
  const [filterByFirstName, setFilterByFirstName] = useState('');
  const [filterByLastName, setFilterByLastName] = useState('');
  const [filterPaymentFromDate, setFilterPaymentFromDate] = useState<Date>();
  const [setFilterFromDate, setFilterToDate] = useState<Date>();

  const [currentBookUrl, setCurrentBookUrl] = useState('');
  const [currentCoverUrl, setCurrentCoverUrl] = useState('');
  const [PDFPagesNumber, setPDFPagesNumber] = useState(null);

  const [coverLoading, setCoverLoading] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);
  const [spineLoading, setSpineLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorPDFPages, setErrorPDFPages] = useState('');
  const [selectedItem, setSelectedItem] = useState<UserOrdersModel>();
  // const orderStatusState = useSelector((state: RootState) => state.selectedOrderStatus);

  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  // const [progress, setProgress] = useState(0);
  const [allOrderStatus, setAllOrderStatus] = useState<OrderStatusDTO[]>([]);

  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state.userOrders);

  useEffect(() => {
    orderStatusRepository.getAll().then((status) => setAllOrderStatus(status));
  }, []);

  useEffect(() => {
    dispatch(findAllOrderStatus());
    dispatch(
      findAllUserOrders({
        id: filterByBookId === '' ? 0 : Number(filterByBookId),
        firstName: filterByFirstName,
        lastName: filterByLastName,
        paymentFromDate: filterPaymentFromDate,
        paymentToDate: setFilterFromDate,
      }),
    );
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      findAllUserOrders({
        id: filterByBookId === '' ? 0 : Number(filterByBookId),
        firstName: filterByFirstName,
        lastName: filterByLastName,
        paymentFromDate: filterPaymentFromDate,
        paymentToDate: setFilterFromDate,
      }),
    );
  };

  const updateTrackingInfo = async (id: number) => {
    const emptyValue = '';
    const order = state.data.find((item) => item.id === id);
    const { value: formValues } = await Swal.fire({
      title: 'Tracking Info',
      html:
        `<p>Tracking Number</p><input placeholder="FedEx #12345678910" id="swal-input1" required value="${
          order?.trackingNumber ?? emptyValue
        }" style="width: 370px" class="swal2-input"/>` +
        `<div style="height: 10px" "></div><p>Tracking URL</p><input placeholder="Tracking URL" required id="swal-input2" value="${
          order?.trackingUrl ?? emptyValue
        }"style="width: 370px" class="swal2-input"/>`,
      focusConfirm: false,
      preConfirm: () => [
        document.getElementById('swal-input1')!,
        document.getElementById('swal-input2')!,
      ],
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#5CACA8',
      showCancelButton: true,
      cancelButtonColor: '#fa4848',
    });

    if (formValues) {
      const trackingNumber: any = formValues[0];
      const trackingUrl: any = formValues[1];

      try {
        await dispatch(
          changeUserOrderTrackingUrl(
            id,
            trackingUrl.value,
            trackingNumber.value,
          ),
        );
        Swal.fire({
          title: 'Updated!',
          text: 'The user will be notified about the status change.',
          icon: 'success',
          confirmButtonText: 'Great!',
          confirmButtonColor: '#5CACA8',
        });
      } catch (error) {
        Swal.fire({
          title: 'An error has occurred.',
          text: 'Try again!',
          icon: 'error',
          confirmButtonText: 'Ok!',
          confirmButtonColor: '#fa4848',
        });
      }
    }
  };

  const onOverrideStatus = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: number,
  ) => {
    setIsModalOpen(false);

    const newStatus = e.target.value;

    const newOrderStatus = allOrderStatus.find(
      (orderStatus) => orderStatus.id === Number(newStatus),
    );

    await Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure about changing the status to ${
        newOrderStatus?.status ?? '---'
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5CACA8',
      cancelButtonColor: '#fa4848',
      confirmButtonText: 'Yes, update it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await dispatch(changeUserOrderStatus(id, +newStatus));
          Swal.fire({
            title: 'Updated!',
            text: 'The user will be notified about the status change.',
            icon: 'success',
            confirmButtonText: 'Great!',
            confirmButtonColor: '#5CACA8',
          });
        } catch (error) {
          Swal.fire({
            title: 'An error has occurred.',
            text: 'Try again!',
            icon: 'error',
            confirmButtonText: 'Ok!',
            confirmButtonColor: '#fa4848',
          });
        }
      },
    });
  };

  const [pdfRepository] = useState<PDFRepository>(new PDFRepository());

  const handleCancelModal = () => {
    setPDFPagesNumber(null);
    setCurrentBookUrl('');
    setCurrentCoverUrl('');
    setIsModalOpen(false);
  };
  const downloadBookPDF = async () => {
    setBookLoading(true);
    const blob = await pdfRepository.getBookPDF(
      (selectedItem?.users_books.id ?? 0).toString(),
    );
    // open ṕdf in a new tab
    const url = window.URL.createObjectURL(blob);
    setBookLoading(false);
    setCurrentBookUrl(url);
    window.open(url);
  };

  const getSpineInfoPDF = async () => {
    setSpineLoading(true);
    try {
      const response = await pdfRepository.getPagesBookPDF(
        selectedItem?.bookUrl ?? '',
        (selectedItem?.users_books.id ?? 0).toString(),
      );
      setPDFPagesNumber(response.body.data);
      setSpineLoading(false);
    } catch (error) {
      setErrorPDFPages(
        'Unable to load the quantity of pages, please try again later.',
      );
    }
  };

  const downloadCoverPDF = async () => {
    setCoverLoading(true);
    const blob = await pdfRepository.getCoverPDF(
      (selectedItem?.users_books.id ?? 0).toString(),
    );
    // open ṕdf in a new tab
    const url = window.URL.createObjectURL(blob);
    setCoverLoading(false);
    setCurrentCoverUrl(url);
    window.open(url);
  };

  return (
    <AnimatedPage>
      <div className="border rounded-md ">
        <Dialog open={isModalOpen} handler={() => {}}>
          <DialogHeader>Details</DialogHeader>
          <DialogBody divider>
            <table className="w-[100%] text-left">
              <tbody className="text-sm">
                <tr>
                  <th>Full name</th>
                  <td>
                    {selectedItem?.user.firstName} {selectedItem?.user.lastName}
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{selectedItem?.user.email}</td>
                </tr>
                <tr>
                  <th>Full address</th>
                  <td>
                    {selectedItem?.user_address.address_line1}
                    {selectedItem?.user_address.address_line2
                      ? `- ${selectedItem?.user_address.address_line2}`
                      : ''}
                    {' - '}
                    {selectedItem?.user_address.city}
                    {' - '}
                    {selectedItem?.user_address.state}
                    {' - '}
                    {selectedItem?.user_address.zip_code}
                    {' - '}
                    {selectedItem?.user_address.country}
                  </td>
                </tr>
                <tr>
                  <th>Cover pdf</th>
                  <td>
                    {currentCoverUrl.length > 0 ? (
                      <Button variant="text" color="blue" className="p-3">
                        <a
                          target="_blank"
                          href={currentCoverUrl}
                          rel="noreferrer"
                        >
                          Open cover
                        </a>
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        color="green"
                        onClick={downloadCoverPDF}
                        disabled={coverLoading}
                        className="flex flex-row gap-3 items-center justify-center p-3"
                      >
                        <span>See cover</span>
                        {coverLoading && <CircleLoading />}
                      </Button>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Book content pdf</th>
                  <td>
                    {selectedItem?.bookUrl &&
                    selectedItem?.bookUrl.length !== 0 ? (
                      <Button
                        variant="text"
                        color="green"
                        className="flex flex-row gap-3 items-center p-2 pl-4"
                      >
                        <a
                          target="_blank"
                          href={selectedItem?.bookUrl}
                          rel="noreferrer"
                        >
                          See book
                        </a>
                      </Button>
                    ) : currentBookUrl.length > 0 ? (
                      <Button variant="text" color="blue" className="p-3">
                        <a
                          target="_blank"
                          href={currentBookUrl}
                          rel="noreferrer"
                        >
                          Open book
                        </a>
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        color="green"
                        onClick={downloadBookPDF}
                        disabled={bookLoading}
                        className="flex flex-row gap-3 items-center justify-center p-3"
                      >
                        <span>See book content</span>
                        {bookLoading && <CircleLoading />}
                      </Button>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Spine dimension</th>
                  <td>
                    {errorPDFPages.length > 0 ? (
                      <p className="text-red-600">{errorPDFPages}</p>
                    ) : PDFPagesNumber ? (
                      <div>
                        <p>{PDFPagesNumber} pages</p>
                        <p>
                          {(PDFPagesNumber * 0.00284 + 0.26).toFixed(3)} inches
                        </p>
                      </div>
                    ) : (
                      <Button
                        variant="text"
                        color="green"
                        onClick={getSpineInfoPDF}
                        disabled={spineLoading}
                        className="flex flex-row gap-3 items-center justify-center p-3"
                      >
                        <span>See spine dimension</span>
                        {spineLoading && <CircleLoading />}
                      </Button>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Quantity</th>
                  <td>{selectedItem?.quantity}</td>
                </tr>
                <tr>
                  <th>Shipping price</th>
                  <td>
                    {(Number(selectedItem?.shippingCost) / 100).toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      },
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Tax</th>
                  <td>
                    {selectedItem
                      ? (
                          ((selectedItem.quantity *
                            selectedItem.price_per_book +
                            Number(selectedItem.shippingCost)) *
                            8.25) /
                          10000
                        ).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                      : '---'}
                  </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>
                    {selectedItem
                      ? (selectedItem.total / 100).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                      : '---'}
                  </td>
                </tr>
                <tr>
                  <th>Payment ID</th>
                  <td>{selectedItem?.paymentId && selectedItem.paymentId}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <div className="w-16">
                      <select
                        className="m-0 p-2 h-9 border border-[#555454] rounded cursor-pointer "
                        onChange={(e) =>
                          onOverrideStatus(
                            e,
                            selectedItem ? selectedItem?.id : 1,
                          )
                        }
                        value={selectedItem?.status.id || 1}
                      >
                        {allOrderStatus.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Order date</th>
                  <td>
                    {selectedItem?.orderAt
                      ? dateTimeFormat(new Date(selectedItem.orderAt))
                      : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleCancelModal}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
          </DialogFooter>
        </Dialog>
        <DataTable
          title={
            <>
              <div className="h-4" />
              <p>Book Orders</p>
              {state.status === StateStatus.LOADING ? (
                <div />
              ) : (
                <CSVLink
                  data={state.data}
                  filename={`book-orders-${new Date().toISOString()}.csv`}
                >
                  <Chip value="download table" color="indigo" />
                </CSVLink>
              )}
              <div className="h-1" />
            </>
          }
          columns={makeBookOrdersTableColumns({
            allOrderStatus,
            onClick: async (userOrdersModel: UserOrdersModel) => {
              setSelectedItem(userOrdersModel);
              setSelectedOrderStatus(userOrdersModel.status.status);
              setIsModalOpen(true);
            },
            onOverrideStatus,
            updateTrackingInfo: updateTrackingInfo,
          })}
          data={state.data}
          pagination
          responsive
          highlightOnHover
          striped
          subHeader
          progressPending={state.status === StateStatus.LOADING}
          subHeaderComponent={[
            <form
              onSubmit={handleSearch}
              className="flex flex-wrap gap-2 justify-end"
            >
              <div className="w-full lg:w-[200px]">
                <Input
                  label="Book ID"
                  color="teal"
                  width="200px"
                  type="number"
                  value={filterByBookId}
                  icon={
                    <GrFormClose
                      onClick={() => setFilterByBookId('')}
                      cursor="pointer"
                    />
                  }
                  onChange={(value: { target: { value: string } }) => {
                    const valueNumber = Number(value.target.value);
                    if (valueNumber < 0) return;
                    setFilterByBookId(value.target.value.trim());
                  }}
                />
              </div>

              <div className="w-full lg:w-[200px]">
                <Input
                  label="First name"
                  color="teal"
                  width="200px"
                  value={filterByFirstName}
                  icon={
                    <GrFormClose
                      onClick={() => setFilterByFirstName('')}
                      cursor="pointer"
                    />
                  }
                  onChange={(value: { target: { value: string } }) =>
                    setFilterByFirstName(value.target.value.trim())
                  }
                />
              </div>

              <div className="w-full lg:w-[200px]">
                <Input
                  label="Last name"
                  color="teal"
                  value={filterByLastName}
                  icon={
                    <GrFormClose
                      onClick={() => setFilterByLastName('')}
                      cursor="pointer"
                    />
                  }
                  onChange={(value: { target: { value: string } }) =>
                    setFilterByLastName(value.target.value.trim())
                  }
                />
              </div>

              <DatePickerField
                onFromDate={(date) => {
                  if (setFilterFromDate === undefined) {
                    setFilterToDate(date);
                  }
                  setFilterPaymentFromDate(date);
                }}
                onToDate={setFilterToDate}
                fromDate={filterPaymentFromDate}
                toDate={setFilterFromDate}
              />

              <button
                type="submit"
                className="w-full xl:w-[100px] py-2 px-3 rounded-md bg-primary-500 hover:bg-primary-600  text-base text-white font-medium"
              >
                Search
              </button>
            </form>,
          ]}
          customStyles={customStyles}
        />
      </div>
    </AnimatedPage>
  );
}
