/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../../app/config/api';

import { ResponseList } from '../../helpers/axiosResponse';
import { StateStatus } from '../../helpers/initialState';

export type TopReadInvitersGraphModel = {
  x: string;
  y?: number;
};

type state = {
  data: TopReadInvitersGraphModel[];
  errorMessage: string;
  days: string;
  fromDate: number; // timestamp
  toDate: number; // timestamp
  status: StateStatus;
};

const initialState = {
  data: [],
  errorMessage: '',
  days: '0',
  fromDate: new Date(new Date().setDate(new Date().getDate() - 30)).getTime(),
  toDate: new Date().getTime(),
  status: StateStatus.INITIAL,
} as state;

// REPOSITORY //
export const findAllTopReadInvitersGraph = createAsyncThunk(
  'topReadInvitersGraph/findAll',
  async ({ days, fromDate, toDate }: any) => {
    // covert timestamp to YYYY-MM-DD
    const url = `/users/graph/top-10-read-inviter?${
      days !== '0' ? `days=${days}` : ''
    }${
      fromDate
        ? `&fromDate=${new Date(fromDate).toISOString().split('T')[0]}`
        : ''
    }${
      toDate ? `&toDate=${new Date(toDate).toISOString().split('T')[0]}` : ''
    }`;
    const response = await api.get<ResponseList>(url);

    const responseData = response.data.body.data;

    const graph = responseData.result.map((item: any) => ({
      x: item.name,
      y: item.count,
    }));
    return graph;
  },
);

const slice = createSlice({
  name: 'topReadInvitersGraph',
  initialState,
  reducers: {
    setDays: (state, { payload }) => {
      state.days = payload;
    },
    setFromDate: (state, { payload }) => {
      state.fromDate = payload;
    },
    setToDate: (state, { payload }) => {
      state.toDate = payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findAllTopReadInvitersGraph.pending, (state) => {
      state.status = StateStatus.LOADING;
    });
    builder.addCase(findAllTopReadInvitersGraph.fulfilled, (state, action) => {
      state.status = StateStatus.SUCCEED;
      state.data = action.payload;
    });
    builder.addCase(findAllTopReadInvitersGraph.rejected, (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.error.message as string;
    });
  },
});

export const {
  setDays,
  setFromDate,
  setToDate,
  onLoading,
  onSuccess,
  onError,
} = slice.actions;
export default slice.reducer;
