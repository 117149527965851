import React from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

interface IButton {
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface IFormInputLoadingButton {
  title: string;
  titleWhenLoading?: string;
  isLoading?: boolean;
  isDisable?: boolean;
  onPress?: () => void;
}

interface ILinkButton extends Omit<IButton, 'onClick'> {
  route?: string;
}

export function Button({ text = 'sem título', onClick }: IButton): JSX.Element {
  return (
    <button
      className="px-4 h-8 bg-primary-500 rounded-full hover:bg-primary-600 text-white text-h4"
      type="button"
      onClick={onClick}
    >
      <strong>{text}</strong>
    </button>
  );
}

export function OutlineButton({
  text = 'sem título',
  onClick,
}: IButton): JSX.Element {
  return (
    <button
      className="px-4 h-8 bg-white rounded-full hover:bg-primary-400 text-primary-600 text-h4 border border-primary-600"
      type="button"
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
}

export function ChartOutlineButton({
  text = 'sem título',
  onClick,
}: IButton): JSX.Element {
  return (
    <button
      className="px-1 h-6 text-sm xl:px-2 xl:h-6 bg-white rounded-full hover:bg-primary-400 text-primary-600 text-h6 border border-primary-600 mx-1"
      type="button"
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
}

export function ChartButton({
  text = 'sem título',
  onClick,
}: IButton): JSX.Element {
  return (
    <button
      className="px-2 h-6 bg-primary-500 rounded-full text-white border text-h6 mx-1"
      type="button"
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  );
}

export function LinkButton({
  text = 'sem título',
  route = '/',
}: ILinkButton): JSX.Element {
  return (
    <Link
      to={route}
      className="w-fit flex space-x-2 items-center justify-between h-9 px-4 bg-primary-500 rounded-full hover:bg-primary-600 text-white text-h5"
    >
      <strong>{text}</strong>
      <FiChevronRight size={18} />
    </Link>
  );
}

export function FormInputLoadingButton({
  title,
  titleWhenLoading = 'Loading',
  isLoading,
  isDisable = false,
  onPress,
}: IFormInputLoadingButton): JSX.Element {
  const bgColor = isLoading || isDisable ? 'bg-disabled' : 'bg-primary-500';
  const hoverBgColor = isLoading || isDisable ? '' : 'hover:bg-primary-600';

  return (
    <button
      type="submit"
      disabled={isDisable || isLoading}
      onClick={onPress}
      className={`flex text-white ${bgColor} ${hoverBgColor} justify-center focus:ring-4 focus:outline-none focus:bg-primary-600 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
    >
      {isLoading ? (
        <svg
          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        ''
      )}

      {isLoading ? titleWhenLoading : title}
    </button>
  );
}
