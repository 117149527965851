export const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#1A2429',
    },
  },
  head: {
    style: {
      fontWeight: '700',
      color: '#fff',
    },
  },
  rows: {
    style: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  pagination: {
    style: {
      color: '#1A2429',
      fontWeight: '700',
      fontSize: '12px',
      minHeight: '56px',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: 'px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#1A2429',
      fill: '#1A2429',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: '#417877',
        fill: '#417877',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#417877',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#417877',
      },
    },
  },
};
