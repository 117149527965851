import { AxiosInstance } from 'axios';
import { api } from '../app/config/api';

export class PDFRepository {
  private api: AxiosInstance;

  constructor() {
    this.api = api;
  }

  public async getBookPDF(bookId: string): Promise<Blob> {
    const response = await this.api.get(
      `/pdf/generate/book/${bookId}/order/true`,
      {
        responseType: 'blob',
      },
    );

    return response.data;
  }

  public async getPagesBookPDF(bookUrl: string, bookId: string): Promise<any> {
    const response = await this.api.get(
      `/pdf/numberPages?bookUrl=${bookUrl}&bookId=${bookId}`,
    );
    return response.data;
  }

  public async getCoverPDF(bookId: string): Promise<Blob> {
    const response = await this.api.get(`/pdf/generate/cover/${bookId}`, {
      responseType: 'blob',
    });

    return response.data;
  }
}
