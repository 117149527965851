/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import * as MaterialTailwind from '@material-tailwind/react';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { AnimatedPage } from '../../../components/AnimatedPage';
import { FormInputLoadingButton } from '../../../components/Button';
import {
  maskToUSCurrency,
  removeMaskedMoney,
} from '../../../utils/currency-formater';
import {
  createPlan,
  PlanModel,
  updatePlan,
} from '../../../../store/reducers/plansReducer';
import { getRandomFourDigits } from '../../../utils/get-random-four-digits';

export type Item = {
  promoItem: string;
  detailedItem: string;
};
const { Input, Button } = MaterialTailwind as any;

export function PlansForm() {
  const [id, setId] = useState<number>();
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [itemList, setItemList] = useState<Item[]>([
    {
      promoItem: '',
      detailedItem: '',
    },
  ]);

  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (location.state !== null) {
      const plan = location.state as PlanModel;
      setId(plan.id);

      setName(plan.name);
      setPrice(plan.price / 100);
      setItemList(plan.itemList);
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (action === 'create') {
      await dispatch(
        createPlan({
          id: getRandomFourDigits(),
          name,
          price: price * 100,
          itemList,
        }),
      );
      setLoading(false);
    } else {
      await dispatch(
        updatePlan(id!, {
          name,
          price: price * 100,
          itemList,
        }),
      );
      setLoading(false);
    }

    navigate(-1);
  };

  const handleItemAdd = () => {
    setItemList((previous) => [
      ...previous,
      {
        promoItem: '',
        detailedItem: '',
      },
    ]);
  };

  const handleItemRemove = (index: number) => {
    const list = [...itemList];
    list.splice(index, 1);
    setItemList(list);
  };

  return (
    <AnimatedPage>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="flex flex-wrap items-center">
          <Link
            to="/admin/plans"
            // className="w-fit flex space-x-2 items-center justify-between h-9 px-4 bg-primary-500 rounded-full hover:bg-primary-600 text-white text-h4"
          >
            <IoArrowBackSharp size={30} />
          </Link>
          <h2 className="ml-8">
            {action === 'create' ? 'Create a new plan' : 'Edit your plan'}
          </h2>
        </div>
        <div className="mt-4 sm:w-full md:w-[70%] xl:w-[50%]">
          <div className="block xl:flex xl:flex-row">
            <div className="xl:w-[72.5%]">
              <Input
                label="Name"
                color="teal"
                size="lg"
                variant="standard"
                value={name}
                required
                className="border-primary-500 hover:border-primary-600 w-full"
                onChange={(e: any) => setName(e.target.value)}
              />
            </div>
            <div className="h-4 sm:h-8 xl:w-8" />
            <div className="w-[10%]">
              <Input
                label="Price"
                color="teal"
                type="text"
                variant="standard"
                value={maskToUSCurrency(price)}
                required
                size="lg"
                className="border-primary-500 hover:border-primary-600 w-full"
                onChange={(e: any) => {
                  setPrice(removeMaskedMoney(e.target.value));
                }}
              />
            </div>
          </div>
          {itemList.map((item, index) => (
            <div key={index}>
              <div className="block xl:flex xl:flex-row mt-4">
                <div>
                  <Input
                    label="Promo item"
                    color="teal"
                    variant="standard"
                    required
                    value={item.promoItem}
                    size="lg"
                    className="border-primary-500 hover:border-primary-600 w-full"
                    onChange={(e: any) =>
                      setItemList((prev) => {
                        prev[index].promoItem = e.target.value;
                        return [...prev];
                      })
                    }
                  />
                </div>
                <div className="h-4 sm:h-8 xl:w-8" />
                <div className="w-full ">
                  <Input
                    label="Detailed item"
                    color="teal"
                    variant="standard"
                    required
                    value={item.detailedItem}
                    size="lg"
                    className="border-primary-500 hover:border-primary-600 w-full"
                    onChange={(e: any) =>
                      setItemList((prev) => {
                        prev[index].detailedItem = e.target.value;
                        return [...prev];
                      })
                    }
                  />
                </div>
                {index > 0 && (
                  <Button
                    variant="text"
                    color="red"
                    size="sm"
                    ripple={false}
                    onClick={() => handleItemRemove(index)}
                  >
                    remove
                  </Button>
                )}
              </div>
              {itemList.length - 1 === index && itemList.length < 10 && (
                <Button variant="text" color="blue" onClick={handleItemAdd}>
                  + add new item
                </Button>
              )}
            </div>
          ))}
        </div>
        <div className="h-8" />
        <FormInputLoadingButton
          title={action === 'create' ? 'Create' : 'Edit'}
          titleWhenLoading={action === 'create' ? 'Creating' : 'Editing'}
          isLoading={loading}
        />
      </form>
    </AnimatedPage>
  );
}
