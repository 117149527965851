/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '../types';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { ResponseListNoPagination } from '../helpers/axiosResponse';

export type OrderStatusModel = {
  id: number;
  status: string;
};

const initialState: InitialState<OrderStatusModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'orderStatus',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllOrderStatus = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());
    const response = await api.get<ResponseListNoPagination>('/orders-status');
    const responseData = response.data.body.data;

    const userOrders: OrderStatusModel[] = responseData.map<OrderStatusModel>(
      (data) => ({
        id: data.id,
        status: data.status,
      }),
    );

    dispatch(onSuccess());
    dispatch(setData(userOrders));
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};
