/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '../types';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { ResponseList } from '../helpers/axiosResponse';
import { api } from '../../app/config/api';
// import { api } from './authReducer';

export type CategoryModel = {
  id: number;
  title: string;
};

const initialState: InitialState<CategoryModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export type CategoryBody = {
  title: string;
};

export const createCategory =
  (body: CategoryBody) => async (dispatch: AppDispatch) => {
    dispatch(onLoading());

    try {
      await api.post<ResponseList>('/categories', body);
      dispatch(onSuccess());
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const findAllCategory = () => async (dispatch: AppDispatch) => {
  dispatch(onLoading());

  try {
    const response = await api.get<ResponseList>('/categories');
    const responseData = response.data.body.data;

    const category: CategoryModel[] = responseData.result.map<CategoryModel>(
      (data) => ({
        id: data.id,
        title: data.title,
      }),
    );

    dispatch(onSuccess());
    dispatch(setData(category));
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const updateCategory =
  (id: number, body: CategoryBody) => async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.patch<ResponseList>(`/categories/${id}`, body);
      dispatch(onSuccess());
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const deleteCategory = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(onLoading());
  await api.delete(`/categories/${id}`);
  dispatch(findAllCategory());
};
