import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'react-router-dom';

export function Header() {
  return (
    <header className="flex justify-between items-center w-full mx-auto px-5 sm:px-10 py-3 shadow-sm shadow-primary-400">
      <ul className="flex space-x-8 text-h4">
        <li>
          <Link to="/">
            <strong>Life</strong>Stories
          </Link>
        </li>
        <li className="hidden sm:block">
          <Link to="/your-library">Library</Link>
        </li>
        <li className="hidden sm:block">
          <Link to="/your-story">Your Story</Link>
        </li>
      </ul>
      <div className="text-white text-h4">
        <button
          className="h-9 flex space-x-2 px-2 items-center justify-between bg-primary-500 rounded-full hover:bg-primary-600"
          type="button"
        >
          <img
            className="rounded-full outline outline-white-100"
            src="https://i2-prod.irishmirror.ie/incoming/article5425955.ece/ALTERNATES/s615b/MOST-BEAUTIFUL-FACES.jpg"
            alt="profile"
            width={20}
          />
          <span>Parker N</span>
          <FiChevronDown size={20} />
        </button>
      </div>
    </header>
  );
}
