import React from 'react';
import { AnimatedPage } from '../components/AnimatedPage';
import { LinkButton } from '../components/Button';

export const PageNotFound = () => (
  <AnimatedPage>
    <section className="py-7 px-5 sm:py-7 sm:px-8 xl:py-6 xl:px-36">
      <h2>Ops! Looks like you are lost.</h2>
      <p className="pt pt-2 pb-10">404 Page Not Found.</p>
      <LinkButton text="Home" route="/" />
    </section>
  </AnimatedPage>
);
