/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

import { useDispatch } from 'react-redux';
import {
  FaqModel,
  reorderFaqQuestion,
} from '../../../../store/reducers/faqReducer';

export type PlansTableColumns = {
  onEdit: (plan: FaqModel) => void;
  onDelete: (id: number) => void;
  isReorder: boolean;
  length: number;
};

export function makeFaqTableColumns({
  onEdit,
  onDelete,
  isReorder,
  length,
}: PlansTableColumns) {
  const dispatch: any = useDispatch();

  return [
    isReorder
      ? {
          name: 'REORDER',
          sortable: true,
          width: '200px',
          selector: (row: any) => row.action,
          cell: (row: any, index: any) => (
            <div className="flex">
              {index < 1 ? null : (
                <>
                  <span className="hover:bg-blue-gray-100 rounded-full p-2">
                    <AiOutlineArrowUp
                      size={20}
                      cursor="pointer"
                      onClick={async () => {
                        console.log('index 1', index);
                        console.log('row.id 1', row.id);
                        await dispatch(reorderFaqQuestion(row.id, index));
                      }}
                    />
                  </span>
                  <div className="w-2" />
                </>
              )}
              {index > length - 2 ? null : (
                <>
                  <span className="hover:bg-blue-gray-100 rounded-full p-2">
                    <AiOutlineArrowDown
                      size={20}
                      cursor="pointer"
                      onClick={async () => {
                        console.log('index 2', index);
                        console.log('row.id 2', row.id);
                        await dispatch(reorderFaqQuestion(row.id, index + 2));
                      }}
                    />
                  </span>
                  <div className="w-2" />
                </>
              )}
            </div>
          ),
        }
      : {
          name: 'ID',
          sortable: true,
          width: '80px',
          selector: (row: any, index: any) => index + 1,
        },
    {
      name: 'QUESTION',
      sortable: true,
      width: '20rem',
      grow: 1,
      wrap: true,
      selector: (row: any) => row.question,
      cell: (row: any) => <div>{row.question}</div>,
    },
    {
      name: 'ANSWER',
      sortable: true,
      maxWidth: '50rem',
      grow: 1,
      selector: (row: any) => row.answer,
      cell: (row: any) => <div>{row.answer}</div>,
    },
    {
      name: 'CATEGORY',
      sortable: true,
      maxWidth: '48rem',
      grow: 1,
      selector: (row: any) => row.category?.title,
      cell: (row: any) => <div>{row.category?.title}</div>,
    },
    {
      name: 'MEDIA TYPE',
      sortable: true,
      grow: 1,
      width: '8rem',
      selector: (row: any) => {
        if (row.mediaLinkType !== null) {
          if (row.mediaLinkType === 0) {
            return <div>Image</div>;
          }
          return <div>Video</div>;
        }
        return <p>--</p>;
      },
    },
    {
      name: 'MEDIA LINK',
      sortable: true,
      grow: 1,
      width: '10rem',
      selector: (row: any) =>
        row.mediaLink?.replace('https://', '')?.replace('www.', ''),
    },
    {
      name: 'ACTIONS',
      wrap: true,
      width: '250px',
      selector: (row: any) => row.action,
      cell: (row: any) => (
        <div className="flex w-full justify-end">
          {row.mediaLink.length !== 0 ? (
            <button className="py-2 px-3 rounded-md bg-blue-500 hover:bg-blue-700  text-xs text-white font-bold">
              <a
                target="_blank"
                href={
                  !row.mediaLink.includes('https://')
                    ? `https://${row.mediaLink}`
                    : row.mediaLink
                }
                rel="noreferrer"
              >
                Open link
              </a>
            </button>
          ) : null}
          <button
            className="py-2 px-3 ml-2 rounded-md bg-btnEdit hover:bg-btnEditOnHover  text-xs text-white font-bold"
            onClick={() =>
              onEdit({
                id: row.id,
                question: row.question,
                answer: row.answer,
                mediaLinkType: row.mediaLinkType,
                mediaLink: row.mediaLink,
                category: row.category.id,
              })
            }
            id={row.action}
          >
            Edit
          </button>
          <button
            className="py-2 px-3 ml-2 rounded-md bg-btnDelete hover:bg-btnDeleteOnHover text-xs text-white font-bold"
            onClick={() => onDelete(row.id)}
            id={row.action}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
}
