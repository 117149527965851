/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { api } from '../app/config/api';

export enum OrderStatusEnum {
  PAID = 'paid',
  DONE = 'done',
  PRINTING = 'printing',
  SHIPPING = 'shipping',
  DELIVERED = 'delivered',
  PROCESSING = 'processing',
}

const specificOrder: OrderStatusEnum[] = [
  OrderStatusEnum.PAID,
  OrderStatusEnum.PRINTING,
  OrderStatusEnum.DONE,
  OrderStatusEnum.SHIPPING,
  OrderStatusEnum.DELIVERED,
];

export type OrderStatusDTO = {
  id: number;
  status: OrderStatusEnum;
};

export class OrderStatusRepository {
  public async getAll(): Promise<OrderStatusDTO[]> {
    const response = await api.get('/orders-status');
    const rawDataList: any[] = response.data.body.data;

    const orderStatusDTO: OrderStatusDTO[] = rawDataList
      .map((rawData) => ({
        id: rawData.id,
        status: this.getOrderStatusEnum(rawData.status),
      }))
      .filter(
        (orderStatus) => orderStatus.status !== OrderStatusEnum.PROCESSING,
      );

    return orderStatusDTO.sort(
      (a, b) =>
        specificOrder.indexOf(a.status) - specificOrder.indexOf(b.status),
    );
  }

  private getOrderStatusEnum(rawData: any): OrderStatusEnum {
    switch (rawData) {
      case 'paid':
        return OrderStatusEnum.PAID;

      case 'done':
        return OrderStatusEnum.DONE;

      case 'printing':
        return OrderStatusEnum.PRINTING;

      case 'shipping':
        return OrderStatusEnum.SHIPPING;

      case 'delivered':
        return OrderStatusEnum.DELIVERED;

      case 'processing':
        return OrderStatusEnum.PROCESSING;

      default:
        return OrderStatusEnum.PRINTING;
    }
  }
}
