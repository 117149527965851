/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';

import { AppDispatch } from '../types';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { ResponseList } from '../helpers/axiosResponse';
import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { PlanModel } from './plansReducer';

export type UserFilter = {
  firstName?: string;
  lastName?: string;
  planId?: number;
  whyWriting?: string;
  registrationFromDate?: Date;
  registrationToDate?: Date;
  lastLoginFromDate?: Date;
  lastLoginToDate?: Date;
  take?: number;
  skip?: number;
};

export type UserPlanModel = {
  id?: string;
  startedAt?: string;
  endAt?: number;
  status?: number;
  plan?: PlanModel;
};

export type UserLoginModel = {
  id?: string;
  createdAt?: string;
};

export type UserModel = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  whyWriting: Array<string>;
  city: string;
  zip: string;
  state: string;
  phone: string;
  refreshToken: string;
  profilePicture: string;
  authorType: string;
  otherFirstName: string;
  otherLastName: string;
  otherEmail: string;
  freeEndsAt: string;
  createdAt: string;
  updatedAt: string;
  userPlan?: UserPlanModel;
  userLogin?: UserLoginModel;
};

const initialState: InitialState<UserModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllUsers =
  ({
    firstName,
    lastName,
    planId,
    whyWriting,
    registrationFromDate,
    registrationToDate,
  }: UserFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      const response = await api.get<ResponseList>(
        `/users?${firstName ? `first_name=${firstName}` : ''}&${
          lastName ? `last_name=${lastName}` : ''
        }&${planId ? `users_plan=${planId}` : ''}&${
          whyWriting ? `why_writing=${whyWriting}` : ''
        }&${
          registrationFromDate
            ? `registration_FromDate=${registrationFromDate}`
            : ''
        }&${
          registrationToDate ? `registration_ToDate=${registrationToDate}` : ''
        }
        `,
      );

      const responseData = response.data.body.data;

      const users: UserModel[] = responseData.result.map<UserModel>((data) => ({
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        password: data.password,
        whyWriting: data.why_writing,
        city: data.city,
        zip: data.zip,
        state: data.state,
        phone: data.phone,
        userPlan: {
          id: data.users_plan?.id,
          status: data.users_plan?.status,
          startedAt: data.users_plan?.started_at,
          endAt: data.users_plan?.end_at,
          plan: {
            id: data.users_plan?.plan.id,
            name: data.users_plan?.plan?.name,
            price: data.users_plan?.plan.price,
            itemList: [
              {
                promoItem: '',
                detailedItem: '',
              },
            ],
          },
        },
        userLogin: {
          id: data.users_login?.id,
          createdAt: data.users_login?.created_at,
        },
        refreshToken: data.refresh_token,
        profilePicture: data.profile_picture,
        authorType: data.author_type,
        otherFirstName: data.other_first_name,
        otherLastName: data.other_last_name,
        otherEmail: data.other_email,
        freeEndsAt: data.free_ends_at,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      }));

      dispatch(onSuccess());
      dispatch(setData(users));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
export const forceDeleteUser =
  (userId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      await api.delete<Response>(`/users/force/delete/${userId}`);
      await Swal.fire('Deleted!', 'This user has been deleted.', 'success');

      dispatch(onSuccess());
    } catch (error) {
      await Swal.fire(
        'Undeleted user!',
        'Unexpected error. Try again!',
        'error',
      );
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
