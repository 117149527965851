import React, { useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';
import { GrFormClose } from 'react-icons/gr';
import { Button, OutlineButton } from './Button';

interface IModal {
  isOpen?: boolean;
  children: React.ReactNode;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  doneButtonText?: string;
  cancelButtonText?: string;
  onDone?: () => void;
}

const overlayStyles = {
  zIndex: 1000,
  background: 'rgba(0,0,0,0.5)',
};
const contentStyles = {
  zIndex: 1000,
  maxWidth: 400,
  height: 240,
  margin: 'auto',
  borderRadius: '10px',
  border: 'none',
};

export function Modal({
  children,
  isOpen = false,
  setOpen,
  title = 'Untitled',
  doneButtonText = 'Done',
  cancelButtonText = 'Cancel',
  onDone,
}: IModal): JSX.Element {
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: overlayStyles,
        content: contentStyles,
      }}
      shouldCloseOnEsc
      onRequestClose={onClose}
      closeTimeoutMS={150}
    >
      <div className="flex flex-row-reverse">
        <GrFormClose
          size={25}
          onClick={onClose}
          className="modal-close-btn absolute hover:cursor-pointer"
        />

        <div className="modal-box flex flex-col h-full w-full p-1">
          <h3 className="w-11/12">{title}</h3>

          <div className="modal-content grow">{children}</div>

          <div className="modal-actions flex justify-end mt-4 space-x-2">
            <OutlineButton text={cancelButtonText} onClick={onClose} />
            <Button text={doneButtonText} onClick={onDone} />
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
