/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import * as MaterialTailwind from '@material-tailwind/react';
import Swal from 'sweetalert2';

import { RootState } from '../../../../store/types';
import { UserDetailsDialog } from './UserDetailsDialog';
import { makeUsersTableColumns } from './UsersTableColumns';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { customStyles } from '../../../components/DataTableStyle';
import { StateStatus } from '../../../../store/helpers/initialState';
import { FilterComponent } from '../../../components/FilterComponent';
import { DatePickerField } from '../../../components/filters/DatePickerField';
import {
  findAllUsersTable,
  updateExpireUserPlan,
  updateUserPlan,
  UserTableModel,
} from '../../../../store/reducers/tables/usersTableReducer';
import { dateTimeFormat } from '../../../utils/date-time-formatter';

const { Chip, Option, Select } = MaterialTailwind as any;
export function Users() {
  const [filterByFirstName, setFilterByFirstName] = useState('');
  const [filterByLastName, setFilterByLastName] = useState('');
  const [filterByWhyWriting, setFilterByWhyWriting] = useState('');
  const [filterByPayingUser, setFilterByPayingUser] = useState('');

  const [filterRegistrationFromDate, setFilterRegistrationFromDate] =
    useState<Date>();
  const [filterRegistrationToDate, setFilterRegistrationToDate] =
    useState<Date>();
  const [filterLastLoginFromDate, setFilterLastLoginFromDate] =
    useState<Date>();
  const [filterLastLoginToDate, setFilterLastLoginToDate] = useState<Date>();

  const [selectedItem, setSelectedItem] = useState<UserTableModel>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state.usersTable);

  const fetchData = async () => {
    await dispatch(
      findAllUsersTable({
        take: perPage,
        page: currentPage,
        firstName: filterByFirstName,
        lastName: filterByLastName,
        payingUser: filterByPayingUser,
        whyWriting: filterByWhyWriting,
        registrationFromDate: filterRegistrationFromDate,
        registrationToDate: filterRegistrationToDate,
        lastLoginFromDate: filterLastLoginFromDate,
        lastLoginToDate: filterLastLoginToDate,
      }),
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        findAllUsersTable({
          take: perPage,
          page: currentPage,
          firstName: filterByFirstName,
          lastName: filterByLastName,
          payingUser: filterByPayingUser,
          whyWriting: filterByWhyWriting,
          registrationFromDate: filterRegistrationFromDate,
          registrationToDate: filterRegistrationToDate,
          lastLoginFromDate: filterLastLoginFromDate,
          lastLoginToDate: filterLastLoginToDate,
        }),
      );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    perPage,
    currentPage,
    filterByFirstName,
    filterByLastName,
    filterByWhyWriting,
    filterByPayingUser,
    filterRegistrationFromDate,
    filterRegistrationToDate,
    filterLastLoginFromDate,
    filterLastLoginToDate,
  ]);

  const handleClear = (label: string) => {
    switch (label) {
      case 'firstName':
        setFilterByFirstName('');
        break;

      case 'lastName':
        setFilterByLastName('');
        break;

      default:
        setFilterByFirstName('');
        setFilterByLastName('');
        break;
    }
  };

  const adjustDateIfPast = (dateString: Date) => {
    const inputDate = new Date(dateString);
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    if (inputDate < today) {
      // Adiciona um ano
      inputDate.setFullYear(inputDate.getFullYear() + 1);
      inputDate.setMonth(today.getMonth() + 1);
    }

    return inputDate;
  };

  const updatePlan = (
    userId: string,
    oldValue: string,
    value: string,
    expireDate: Date,
  ) => {
    const adjustedTime = adjustDateIfPast(expireDate);
    Swal.fire({
      title: 'Are you sure?',
      html: `This action will change the user's plan.<br/> <br/> <b>${oldValue}</b> to <b>${value}</b> <br>${dateTimeFormat(
        adjustedTime,
      )}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#20b329',
      cancelButtonColor: '#3085d6',
      showLoaderOnConfirm: true,
      confirmButtonText: 'Yes, update it!',
      preConfirm: async () => {
        try {
          await dispatch(
            updateUserPlan(userId, {
              end_at: adjustDateIfPast(new Date(expireDate)),
              newPlanName: value,
              oldPlanName: oldValue,
            }),
          );
          await fetchData();
          Swal.fire({
            title: 'Updated!',
            icon: 'success',
            confirmButtonText: 'Great!',
            confirmButtonColor: '#5CACA8',
          });
        } catch (error) {
          Swal.fire({
            title: 'An error has occurred.',
            text: 'Try again!',
            icon: 'error',
            confirmButtonText: 'Ok!',
            confirmButtonColor: '#fa4848',
          });
        }
      },
    });
  };

  const updatePlanExpireDate = (
    userId: string,
    expireDate: Date,
    isPremium: boolean,
  ) => {
    const date = new Date(expireDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    Swal.fire({
      title: 'Are you sure?',
      html: `<p>This is going to change the user's plan expiration date.</p><br><input placeholder="mm/dd/yyyy" type="date" id="datetime-input" class="swal2-input" value="${formattedDate}">`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#20b329',
      cancelButtonColor: '#3085d6',
      showLoaderOnConfirm: true,
      confirmButtonText: 'Yes, update it!',
      preConfirm: async () => {
        try {
          const dateValue = document.getElementById(
            'datetime-input',
          ) as HTMLInputElement;

          const dateString = new Date(dateValue.value!);

          dateString.setDate(dateString.getDate() + 1);
          dateString.setHours(dateString.getHours() + 3);

          const expireDate = new Date(dateString).toLocaleString('en-US', {
            timeZone: 'America/New_York',
          });

          await dispatch(
            updateExpireUserPlan(userId, {
              end_at: new Date(expireDate),
              isPremium,
            }),
          );
          await fetchData();
          Swal.fire({
            title: 'Updated!',
            icon: 'success',
            confirmButtonText: 'Great!',
            confirmButtonColor: '#5CACA8',
          });
        } catch (error) {
          Swal.fire({
            title: 'An error has occurred.',
            text: 'Try again!',
            icon: 'error',
            confirmButtonText: 'Ok!',
            confirmButtonColor: '#fa4848',
          });
        }
      },
    });
  };

  return (
    <AnimatedPage>
      <div className="border rounded-md">
        <UserDetailsDialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDelete={fetchData}
          user={selectedItem!}
        />
        <DataTable
          title={
            <>
              <div className="h-4" />
              <p>Users</p>
              {state.status === StateStatus.LOADING ? (
                <div />
              ) : (
                <CSVLink
                  data={state.data.data.map((item, index) => ({
                    id: index + 1,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    email: item.email,
                    totalImages: item.totalImages,
                    lastLoginDate: item.lastLoginDate,
                    activatedPlan: item.activatedPlan,
                    planExpireDate: item.planExpireDate,
                    registrationDate: item.registrationDate,
                    totalAnsweredQuestions: item.totalAnsweredQuestions,
                  }))}
                  filename={`users-${new Date().toISOString()}.csv`}
                >
                  <Chip value="download table" color="indigo" />
                </CSVLink>
              )}
              <div className="h-1" />
            </>
          }
          striped
          subHeader
          pagination
          responsive
          highlightOnHover
          paginationServer
          paginationRowsPerPageOptions={[10, 15, 20, 30]}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPage={perPage}
          onChangeRowsPerPage={(currentPerPage) => setPerPage(currentPerPage)}
          paginationTotalRows={state.data.pagination.totalItems}
          progressPending={state.status === StateStatus.LOADING}
          data={state.data.data}
          onSort={(column, direction) => {
            let columnName = 'created_at';

            switch (column.name) {
              case 'REGISTRATION DATE':
                columnName = 'created_at';
                break;
              case 'FIRST NAME':
                columnName = 'first_name';
                break;
              case 'LAST NAME':
                columnName = 'last_name';
                break;
              case 'EMAIL':
                columnName = 'email';
                break;
              default:
                columnName = 'created_at';
                break;
            }

            dispatch(
              findAllUsersTable({
                sortBy: columnName,
                orderBy: direction === 'asc' ? 'ASC' : 'DESC',
                page: currentPage,
                firstName: filterByFirstName,
                lastName: filterByLastName,
                payingUser: filterByPayingUser,
                whyWriting: filterByWhyWriting,
                registrationFromDate: filterRegistrationFromDate,
                registrationToDate: filterRegistrationToDate,
                lastLoginFromDate: filterLastLoginFromDate,
                lastLoginToDate: filterLastLoginToDate,
              }),
            );
          }}
          columns={makeUsersTableColumns({
            onClick: (item: UserTableModel) => {
              setSelectedItem(item);
              setIsModalOpen(true);
            },
            PLAN_OPTIONS: state.data.plans
              ? state.data.plans.filter(
                  (item) => item.name !== 'Subscription Gift',
                )
              : [],
            updatePlan,
            updatePlanExpireDate,
          })}
          subHeaderComponent={[
            <>
              <div className="flex w-full items-end gap-3">
                <FilterComponent
                  label="Filter by first name"
                  onFilter={(e) => setFilterByFirstName(e.target.value)}
                  onClear={() => handleClear('name')}
                  filterText={filterByFirstName}
                />

                <FilterComponent
                  label="Filter by last name"
                  onFilter={(e) => setFilterByLastName(e.target.value)}
                  onClear={() => handleClear('chapter')}
                  filterText={filterByLastName}
                />

                <DatePickerField
                  label="Registration date range"
                  onFromDate={(date) => {
                    if (setFilterRegistrationFromDate === undefined) {
                      setFilterRegistrationToDate(date);
                    }
                    setFilterRegistrationFromDate(date);
                  }}
                  onToDate={setFilterRegistrationToDate}
                  fromDate={filterRegistrationFromDate}
                  toDate={filterRegistrationToDate}
                />

                <DatePickerField
                  label="Last login date range"
                  onFromDate={(date) => {
                    if (setFilterLastLoginFromDate === undefined) {
                      setFilterLastLoginToDate(date);
                    }
                    setFilterLastLoginFromDate(date);
                  }}
                  onToDate={setFilterLastLoginToDate}
                  fromDate={filterLastLoginFromDate}
                  toDate={filterLastLoginToDate}
                />
              </div>

              <div className="flex w-full items-end gap-3 my-3">
                <Select
                  label="Select why you are writing"
                  size="md"
                  color="teal"
                  value={filterByWhyWriting}
                  onChange={(value: any) =>
                    setFilterByWhyWriting(String(value))
                  }
                >
                  <Option value="">All</Option>
                  <Option value="for my family">For my family</Option>
                  <Option value="for my friends">For my friends</Option>
                  <Option value="for myself">For myself</Option>
                  <Option value="for my professional life">
                    For my professional life
                  </Option>
                  <Option value="other">Other</Option>
                </Select>

                <Select
                  label="Select paying user"
                  size="md"
                  color="teal"
                  value={filterByPayingUser}
                  onChange={(value: any) =>
                    setFilterByPayingUser(String(value))
                  }
                >
                  <Option value="">All</Option>
                  <Option value="paying only">Paying only</Option>
                  <Option value="not paying only">Not paying only</Option>
                </Select>
              </div>
            </>,
          ]}
          customStyles={customStyles}
        />
      </div>
    </AnimatedPage>
  );
}
