/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '../types';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { ResponseList } from '../helpers/axiosResponse';
import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { CategoryModel } from './categoryReducer';

export type FaqModel = {
  id: number;
  question: string;
  answer: string;
  mediaLink: string;
  mediaLinkType: number;
  category?: CategoryModel;
  categoryId?: number;
  created_at?: Date;
};

const initialState: InitialState<FaqModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export type FaqBody = {
  category: number;
  question: string;
  answer: string;
  mediaLink: string;
  mediaLinkType: number | null;
};

export const createFaq = (body: FaqBody) => async (dispatch: AppDispatch) => {
  dispatch(onLoading());

  try {
    await api.post<ResponseList>('/faq', body);
    dispatch(onSuccess());
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const findAllFaq = () => async (dispatch: AppDispatch) => {
  dispatch(onLoading());

  try {
    const response = await api.get<ResponseList>('/faq');
    const responseData = response.data.body.data;

    const faq: FaqModel[] = responseData.result.map<FaqModel>((data) => ({
      id: data.id,
      answer: data.answer,
      question: data.question,
      mediaLink: data.mediaLink,
      mediaLinkType: data.mediaLinkType,
      category: {
        id: data.category?.id,
        title: data.category?.title,
      },
      created_at: data.created_at,
    }));

    dispatch(onSuccess());
    dispatch(setData(faq));
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const updateFaq =
  (id: number, body: FaqBody) => async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.put<ResponseList>(`/faq/${id}`, body);
      dispatch(onSuccess());
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const deleteFaq = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());
    await api.delete(`/faq/${id}`);
    dispatch(findAllFaq());
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const reorderFaqQuestion =
  (
    id: number,
    newPosition: number,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // dispatch(onLoading());
      await api.patch<ResponseList>(`/faq/${id}/reorder/${newPosition}`);

      dispatch(findAllFaq());
      dispatch(onSuccess());

      if (handleSuccess)
        handleSuccess('Question has been successfully reordered.');
    } catch (error) {
      if (handleError) handleError('Failed to reorder question.');
      dispatch(onSuccess());
      // dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
