/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as MaterialTailwind from '@material-tailwind/react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';

import 'chartjs-adapter-moment';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import {
  findAllTopContributionInvitersGraph,
  setDays,
  setFromDate,
  setToDate,
} from '../../../../../store/reducers/graphs/topContributionsReducer';
import { RootState } from '../../../../../store/types';
import { DatePickerField } from '../../../../components/filters/DatePickerField';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  BarElement,
);
const { Input, Option, Select } = MaterialTailwind as any;
export function TopContributionsInviterChart() {
  const [hasCustom, setHasCustom] = React.useState(false);

  const [menuOptionSelected, setMenuOptionSelected] =
    React.useState('last-days');

  const dispatch: any = useDispatch();
  const filterByLastDays = useSelector(
    (state: RootState) => state.graphTopContribution.days,
  );
  const filterFromDate = useSelector(
    (state: RootState) => state.graphTopContribution.fromDate,
  );
  const filterToDate = useSelector(
    (state: RootState) => state.graphTopContribution.toDate,
  );

  const data = useSelector(
    (state: RootState) => state.graphTopContribution.data,
  );

  const updateData = () => {
    dispatch(
      findAllTopContributionInvitersGraph({
        days: filterByLastDays,
        fromDate: filterFromDate,
        toDate: filterToDate,
      }),
    );
  };

  React.useEffect(() => {
    updateData();
  }, []);

  React.useEffect(() => {
    updateData();
  }, [filterByLastDays, filterFromDate, filterToDate]);

  const filterSelected = () =>
    menuOptionSelected === 'last-days' ? (
      <>
        <div className="w-full lg:w-[200px]">
          <Select
            label="Select last days"
            size="md"
            color="teal"
            value={filterByLastDays}
            onChange={(value: any) => {
              setHasCustom(value === 'custom');
              dispatch(setDays(value));
              updateData();
            }}
          >
            <Option value="7">Last 7 days</Option>
            <Option value="15">Last 15 days</Option>
            <Option value="30">Last 30 days</Option>
            <Option value="custom">Custom</Option>
          </Select>
        </div>
        {hasCustom ? (
          <div className="w-full lg:w-[200px]">
            <Input
              label="Custom days"
              color="teal"
              type="number"
              onChange={(value: any) => {
                const valueNumber = Number(value.target.value);
                if (valueNumber < 1) return;
                dispatch(setDays(String(value.target.value)));
                updateData();
              }}
            />
          </div>
        ) : (
          <div />
        )}
      </>
    ) : (
      <DatePickerField
        onFromDate={(value: Date) => {
          dispatch(setFromDate(value.getTime()));
          updateData();
        }}
        onToDate={(value: Date) => {
          dispatch(setToDate(value.getTime()));
          updateData();
        }}
        fromDate={new Date(filterFromDate)}
        toDate={new Date(filterToDate)}
      />
    );

  const handleChangeMenuOption = (value: string) => {
    setMenuOptionSelected(String(value));
    if (value === 'last-days') {
      dispatch(setFromDate(0));
      dispatch(setToDate(0));
      dispatch(setDays('7'));
    }
    if (value === 'date-range') {
      dispatch(setDays('0'));
      dispatch(setFromDate(new Date().getTime()));
      dispatch(setToDate(new Date().getTime()));
    }
    updateData();
  };

  return (
    <div className="rounded-md w-full lg:w-[70%]">
      <div className="flex gap-2 flex-wrap">
        <div className="w-full lg:w-[200px]">
          <Select
            label="Filter options"
            size="md"
            color="teal"
            variant="standard"
            value={menuOptionSelected}
            onChange={(value: any) => {
              handleChangeMenuOption(String(value));
            }}
          >
            <Option value="last-days">Last days</Option>
            <Option value="date-range">Date range</Option>
          </Select>
        </div>
        {filterSelected()}
      </div>
      <div className="flex w-full items-center">
        <Bar
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Top 10 contributions inviters',
              },
            },
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 10,
              },
              x: {
                ticks: {
                  autoSkip: false,
                  maxRotation: 0,
                  minRotation: 0,
                },
              },
            },
          }}
          data={{
            datasets: [
              {
                label: 'Added contributions',
                data: data,
                borderColor: '#BB8FCE',
                backgroundColor: '#BB8FCE',
              },
            ],
          }}
        />
      </div>
    </div>
  );
}
