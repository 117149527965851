import { configureStore, combineReducers } from '@reduxjs/toolkit';
import appReducer from './reducers/appReducer';
import authReducer from './reducers/authReducer';
import bookReducer from './reducers/bookReducer';
import plansReducer from './reducers/plansReducer';
import chaptersReducer from './reducers/chaptersReducer';
import userOrdersReducer from './reducers/userOrdersReducer';
import faqReducer from './reducers/faqReducer';
import questionsReducer from './reducers/questionsReducer';
import usersReducer from './reducers/usersReducer';
import signUpsReducer from './reducers/graphs/signUpsReducer';
import contributionsReducer from './reducers/graphs/contributionsReducer';
import signInsReducer from './reducers/graphs/signInsReducer';
import questionAnsweringsReducer from './reducers/graphs/questionAnsweringsReducer';
import readInvitesReducer from './reducers/graphs/readInvitesReducer';
import topContributionsReducer from './reducers/graphs/topContributionsReducer';
import topReadInvitersReducer from './reducers/graphs/topReadInvitersReducer';
import bookOrdersReducer from './reducers/graphs/bookOrdersReducer';
import planActivationsReducer from './reducers/graphs/planActivationsReducer';
import bookOrdersTableReducer from './reducers/graphs/bookOrdersTableReducer';
import orderStatusReducer from './reducers/orderStatusReducer';
import usersContributorsReducer from './reducers/usersContributorsReducer';
import bookReadersReducer from './reducers/bookReadersReducer';
import categoryReducer from './reducers/categoryReducer';
import usersTableReducer from './reducers/tables/usersTableReducer';
import usersPlansTableReducer from './reducers/tables/usersPlansTableReducer';
import questionsTableReducer from './reducers/tables/questionsTableReducer';

const reducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  book: bookReducer,
  bookReaders: bookReadersReducer,
  userOrders: userOrdersReducer,
  usersContributors: usersContributorsReducer,
  users: usersReducer,
  plans: plansReducer,
  chapters: chaptersReducer,
  faq: faqReducer,
  category: categoryReducer,
  questions: questionsReducer,
  graphSignUps: signUpsReducer,
  graphSignIns: signInsReducer,
  graphContributions: contributionsReducer,
  graphTopContribution: topContributionsReducer,
  graphQuestionAnswerings: questionAnsweringsReducer,
  graphReadInvites: readInvitesReducer,
  graphTopReadInvites: topReadInvitersReducer,
  graphBookOrders: bookOrdersReducer,
  graphPlansActivation: planActivationsReducer,
  tableBookOrders: bookOrdersTableReducer,
  orderStatus: orderStatusReducer,
  usersTable: usersTableReducer,
  chapterQuestionsTable: questionsTableReducer,
  usersPlansTable: usersPlansTableReducer,
});

export const store = configureStore({
  reducer,
});
