/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../../app/config/api';
// import { api } from '../authReducer';
import { AppDispatch } from '../../types';
import { ErrorMessage } from '../../../app/constants/error_messages';
import { InitialState, StateStatus } from '../../helpers/initialState';

export type UserFilter = {
  sortBy?: string;
  orderBy?: string;
  take?: number;
  page?: number;
  firstName?: string;
  lastName?: string;
  payingUser?: string;
  whyWriting?: string;
  lastLoginToDate?: Date;
  lastLoginFromDate?: Date;
  registrationToDate?: Date;
  registrationFromDate?: Date;
};

export type UserTableModel = {
  userId: string;
  firstName: string;
  lastName: string;
  birthday: string;
  email: string;
  registrationDate: Date;
  lastLoginDate: Date;
  activatedPlan: string | null;
  statusPlan: string | null;
  freeExpireDate: Date | null;
  planExpireDate: Date | null;
  totalImages: number;
  totalAnsweredQuestions: number;
};

export type Pagination = {
  page: number;
  perPage: number;
  totalPages: number;
  totalItems: number;
};

export type updateUserPlanDTO = {
  oldPlanName: string;
  newPlanName: string;
  end_at: Date;
};

export type updateExpireUserPlanDTO = {
  isPremium: boolean;
  end_at: Date;
};

export type Plan = {
  id: number;
  name: string;
};

export type UsersTable = {
  pagination: Pagination;
  data: UserTableModel[];
  plans?: Plan[];
};

const initialState: InitialState<UsersTable> = {
  status: StateStatus.INITIAL,
  data: {
    data: [],
    pagination: {
      page: 0,
      perPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  errorMessage: '',
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllUsersTable =
  ({
    sortBy,
    orderBy,
    page,
    firstName,
    take = 10,
    lastName,
    payingUser,
    whyWriting,
    registrationFromDate,
    registrationToDate,
    lastLoginFromDate,
    lastLoginToDate,
  }: UserFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      const firstNameString = firstName ? `first_name=${firstName}` : '';
      const lastNameString = lastName ? `last_name=${lastName}` : '';
      const payingUserString = payingUser ? `paying_user=${payingUser}` : '';
      const whyWritingString = whyWriting ? `why_writing=${whyWriting}` : '';
      const sortByString = sortBy ? `sortBy=${sortBy}` : '';
      const orderByString = orderBy ? `orderBy=${orderBy}` : '';

      const registrationFromDateString = registrationFromDate?.toISOString()
        ? `registration_fromDate=${registrationFromDate?.toISOString()}`
        : '';

      const registrationToDateString = registrationToDate?.toISOString()
        ? `registration_toDate=${registrationToDate?.toISOString()}`
        : '';

      const lastLoginFromDateString = lastLoginFromDate?.toISOString()
        ? `last_login_fromDate=${lastLoginFromDate?.toISOString()}`
        : '';

      const lastLoginToDateString = lastLoginToDate?.toISOString()
        ? `last_login_toDate=${lastLoginToDate?.toISOString()}`
        : '';

      const [response, responsePlan] = await Promise.all([
        api.get(
          `/users/admin/table?take=${take}&page=${page}&${firstNameString}&${lastNameString}&${payingUserString}&${whyWritingString}&${registrationFromDateString}&${registrationToDateString}&${lastLoginFromDateString}&${lastLoginToDateString}&${sortByString}&${orderByString}`,
        ),
        api.get('/plans'),
      ]);

      const responseData: any = response.data.body.data;
      const planData: any = responsePlan.data.body.data;

      const usersTable: UsersTable = {
        pagination: {
          page: responseData.pagination.page,
          perPage: responseData.pagination.per_page,
          totalItems: responseData.pagination.total_items,
          totalPages: responseData.pagination.total_pages,
        },
        data:
          responseData.data.map((data: any) => ({
            email: data.email,
            userId: data.userId,
            lastName: data.lastName,
            firstName: data.firstName,
            birthday: data.birthday,
            totalImages: data.totalImages,
            activatedPlan: data.activatedPlan,
            statusPlan: data.statusPlan,
            plan: data.plan,
            lastLoginDate: data.lastLoginDate,
            whyWritting: data.why_writing,
            planExpireDate: data.planExpireDate,
            freeExpireDate: data.freeExpireDate,
            registrationDate: data.registrationDate,
            totalAnsweredQuestions: data.totalAnsweredQuestions,
          })) ?? [],
        plans:
          planData.result.map((data: any) => ({
            id: data.id,
            name: data.name,
          })) ?? [],
      };

      dispatch(onSuccess());
      dispatch(setData(usersTable));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const updateUserPlan =
  (id: string, body: updateUserPlanDTO) => async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.patch(`/users/update-user-plan/${id}`, body);
      dispatch(onSuccess());
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const updateExpireUserPlan =
  (id: string, body: updateExpireUserPlanDTO) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.patch(`/users/update-expire-user-plan/${id}`, body);
      dispatch(onSuccess());
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
