import React from 'react';
import { motion } from 'framer-motion';

interface IAnimated {
  children: JSX.Element;
}

export function AnimatedPage({ children }: IAnimated): JSX.Element {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}
