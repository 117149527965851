// import axios from 'axios';
// import Cookies from 'universal-cookie';
// import { validAndRefreshToken } from '../../../store/reducers/authReducer';

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
// const REQUEST_TIMEOUT_MS = 250_000;

// const cookies = new Cookies();

// axios.interceptors.response.use(
//   (response) => response,
//   async (error: any) => {
//     if (error.response.status === (401 || 403)) {
//       const refreshToken = cookies.get('lsRefreshToken');
//       const token = cookies.get('lsToken');

//       const bodyAuth = {
//         refreshToken,
//         token,
//       };

//       validAndRefreshToken(bodyAuth);
//       // const response = await api.post('/auth/valid-token', bodyAuth);
//       // console.log('omg error', response);
//     }
//     // adminLogout()
//     return Promise.reject(error);
//   },
// );

// const api = axios.create({
//   baseURL: BASE_API_URL,
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   timeout: REQUEST_TIMEOUT_MS,
// });

// export default api;

import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import { validAndRefreshToken } from '../../../store/reducers/authReducer';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const REQUEST_TIMEOUT_MS = 250_000;

const cookies = new Cookies();

axios.interceptors.response.use(
  (response) => response,
  async (error: any) => {
    if (error.response.status === (401 || 403)) {
      const refreshToken = cookies.get('lsRefreshToken');
      const token = cookies.get('lsToken');

      const bodyAuth = {
        refreshToken,
        token,
      };

      validAndRefreshToken(bodyAuth);
      // const response = await api.post('/auth/valid-token', bodyAuth);
      // console.log('omg error', response);
    }
    // adminLogout()
    return Promise.reject(error);
  },
);
const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: REQUEST_TIMEOUT_MS,
});

const useProgressBar = () => {
  const [progress, setProgress] = useState(0);

  // Adiciona um interceptor de request para atualizar o progresso
  api.interceptors.request.use((config: AxiosRequestConfig) => {
    config.onUploadProgress = (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setProgress(percentCompleted);
    };
    return config;
  });

  // Limpa o progresso quando a requisição é concluída
  api.interceptors.response.use(
    (response) => {
      setProgress(0);
      return response;
    },
    (error) => {
      setProgress(0);
      return Promise.reject(error);
    },
  );

  return progress;
};

export { api, useProgressBar };
