/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import * as MaterialTailwind from '@material-tailwind/react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StateStatus } from '../../../../store/helpers/initialState';
import {
  deleteQuestion,
  QuestionModel,
} from '../../../../store/reducers/questionsReducer';
import { RootState } from '../../../../store/types';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { LinkButton } from '../../../components/Button';
import { customStyles } from '../../../components/DataTableStyle';
import { Modal } from '../../../components/Modal';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../components/toast/ShowToast';
import { makeQuestionsTableColumns } from './QuestionsTableColumns';
import { LoaderSVG } from '../../../components/LoaderSVG';
import { FilterComponent } from '../../../components/FilterComponent';
import { findAllQuestionsTable } from '../../../../store/reducers/tables/questionsTableReducer';

const { Checkbox, Chip } = MaterialTailwind as any;
export function Questions() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [removeItemID, setRemoveItemID] = useState<number>();
  const [isReorder, setReorder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterByName, setFilterByName] = useState('');
  const [filterByChapter, setFilterByChapter] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state.chapterQuestionsTable);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        findAllQuestionsTable({
          take: perPage,
          page: currentPage,
          name: filterByName,
          chapter: filterByChapter,
        }),
      );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [perPage, currentPage, filterByName, filterByChapter]);

  const handleClear = (label: string) => {
    setResetPaginationToggle(!resetPaginationToggle);
    switch (label) {
      case 'name':
        setFilterByName('');
        break;

      case 'chapter':
        setFilterByChapter('');
        break;
      default:
        setFilterByName('');
        setFilterByChapter('');
        break;
    }
  };

  const onRemoveItem = () => {
    if (removeItemID !== undefined)
      dispatch(deleteQuestion(removeItemID, showSuccessToast, showErrorToast));
    setModalOpen(false);
  };

  return (
    <AnimatedPage>
      <div className="border rounded-md ">
        <Modal
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          onDone={onRemoveItem}
          title="Are you sure you want to delete it?"
        >
          <p className="pt-4">The data will be permanently deleted.</p>
          <p>This action is irreversible.</p>
        </Modal>
        <DataTable
          title={
            <>
              <div className="flex flex-wrap items-center">
                <div className="h-4" />
                <p>Questions</p>
                <div className="w-3" />
                {loading || state.status === StateStatus.LOADING ? (
                  <LoaderSVG />
                ) : null}
              </div>
              {state.status === StateStatus.LOADING ? (
                <div />
              ) : (
                <CSVLink
                  data={state.data.data}
                  filename={`questions-${new Date().toISOString()}.csv`}
                >
                  <Chip value="download table" color="indigo" />
                </CSVLink>
              )}
              <div className="h-1" />
            </>
          }
          columns={makeQuestionsTableColumns({
            onEdit: (question: QuestionModel) => {
              navigate('/admin/questions/form/edit', {
                state: {
                  id: question.id,
                  name: question.name,
                  order: question.order,
                  can_suggest: question.can_suggest,
                  chapter: question.chapter && question.chapter,
                },
              });
            },
            onDelete: (id) => {
              setRemoveItemID(id);
              setModalOpen(true);
            },
            length: state.data.pagination.totalItems,
            isReorder,
            loading,
            setLoading,
          })}
          data={state.data.data}
          pagination
          responsive
          highlightOnHover
          striped
          paginationServer
          paginationRowsPerPageOptions={[10, 15, 20, 30]}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPage={perPage}
          onChangeRowsPerPage={(currentPerPage) => setPerPage(currentPerPage)}
          paginationTotalRows={state.data.pagination.totalItems}
          progressPending={state.status === StateStatus.LOADING}
          subHeader
          subHeaderComponent={
            <div className="flex items-center">
              <div className="flex">
                <FilterComponent
                  label="Filter by name"
                  onFilter={(e) => setFilterByName(e.target.value)}
                  onClear={() => handleClear('name')}
                  filterText={filterByName}
                />
                <div className="w-[3rem] " />
                <FilterComponent
                  label="Filter by chapter"
                  onFilter={(e) => setFilterByChapter(e.target.value)}
                  onClear={() => handleClear('chapter')}
                  filterText={filterByChapter}
                />
              </div>
              <div className="w-[3rem] " />
              <Checkbox
                color="teal"
                label="Reorder"
                checked={isReorder}
                onChange={() => {
                  setReorder(!isReorder);
                }}
              />
            </div>
          }
          actions={
            <div className="flex items-center">
              <LinkButton
                text="Create question"
                route="/admin/questions/form/create"
              />
            </div>
          }
          customStyles={customStyles}
        />
      </div>
    </AnimatedPage>
  );
}
