/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import * as MaterialTailwind from '@material-tailwind/react';

import { IoArrowBackSharp } from 'react-icons/io5';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AnimatedPage } from '../../../components/AnimatedPage';
import {
  ChapterModel,
  createChapter,
  updateChapter,
} from '../../../../store/reducers/chaptersReducer';
import { FormInputLoadingButton } from '../../../components/Button';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../components/toast/ShowToast';

const { Input } = MaterialTailwind as any;

export function ChaptersForm() {
  const [id, setId] = useState<number>();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (location.state !== null) {
      const chapter = location.state as ChapterModel;
      setId(chapter.id);

      setName(chapter.name);
      setOrder(chapter.order);
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (action === 'create') {
      await dispatch(
        createChapter(
          {
            name,
            // order,
          },
          showSuccessToast,
          showErrorToast,
        ),
      );
      setLoading(false);
    } else {
      await dispatch(
        updateChapter(
          id!,
          {
            name,
            // order,
          },
          showSuccessToast,
          showErrorToast,
        ),
      );
      setLoading(false);
    }

    navigate(-1);
  };

  return (
    <AnimatedPage>
      <div>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="flex flex-wrap items-center">
            <Link
              to="/admin/chapters"
              // className="w-fit flex space-x-2 items-center justify-between h-9 px-4 bg-primary-500 rounded-full hover:bg-primary-600 text-white text-h4"
            >
              <IoArrowBackSharp size={30} />
            </Link>
            <h2 className="ml-8">Create a new chapter</h2>
          </div>
          <div className="mt-4 sm:w-full md:w-[70%] xl:w-[50%]">
            <div className="block xl:flex xl:flex-row">
              <div className="xl:w-[72.5%]">
                <Input
                  label="Name"
                  color="teal"
                  size="lg"
                  variant="standard"
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                  className="border-primary-500 hover:border-primary-600 w-full"
                />
              </div>
              {/* {action?.includes('edit') ? (
                <>
                  <div className="h-4 sm:h-8 xl:w-8" />
                  <div className="w-[10%]">
                    <Input
                      label="Order"
                      color="teal"
                      variant="standard"
                      value={order}
                      onChange={(e) => setOrder(Number(e.target.value))}
                      size="lg"
                      className="border-primary-500 hover:border-primary-600 w-full"
                    />
                  </div>
                </>
              ) : null} */}
            </div>
          </div>
          <div className="h-8" />
          <FormInputLoadingButton
            title={action === 'create' ? 'Create' : 'Edit'}
            titleWhenLoading={action === 'create' ? 'Creating' : 'Editing'}
            isLoading={loading}
          />
        </form>
      </div>
    </AnimatedPage>
  );
}
