/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../../types';
import { ErrorMessage } from '../../../app/constants/error_messages';
import { InitialState, StateStatus } from '../../helpers/initialState';
import { api } from '../../../app/config/api';
import { ResponseList } from '../../helpers/axiosResponse';

export type UserOrdersFilter = {
  days?: string;
  fromDate?: Date;
  toDate?: Date;
};

type UserModel = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  whyWriting: string;
  city: string;
  zip: string;
  state: string;
  phone: string;
  profilePicture: string;
  authorType: string;
  otherFirstName: string;
  otherLastName: string;
  otherEmail: string;
  freeEndsAt: string;
  createdAt: Date;
  updatedAt: Date;
};

export type UserOrdersModel = {
  id: number;
  user: UserModel;
  status: string;
  quantity: number;
  price_per_book: number;
  estimated_shipping_cost: number;
  shippingMethod: string;
  shippingInfo: string;
  orderAt: string;
  createdAt: Date;
  updatedAt: Date;
};

const initialState: InitialState<UserOrdersModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'pendingBookOrders',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export const findAllPendingUserOrders =
  ({ days = '', fromDate, toDate }: UserOrdersFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      const response = await api.get<ResponseList>(
        `/users-orders/graph/pending-only?days=${days}&${
          fromDate ? `fromDate=${new Date(fromDate).toISOString()}` : ''
        }&${toDate ? `toDate=${new Date(toDate).toISOString()}` : ''}`,
      );

      const responseData = response.data.body.data;

      let userOrders: UserOrdersModel[] = [];

      if (responseData.result.length !== 0) {
        userOrders = responseData.result.map<UserOrdersModel>((data) => ({
          id: data.id,
          user: {
            id: data.user.id ?? 0,
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
            whyWriting: data.user.why_writing,
            city: data.user.city,
            zip: data.user.zip,
            state: data.user.state,
            phone: data.user.phone,
            profilePicture: data.user.profile_picture,
            authorType: data.user.author_type,
            otherFirstName: data.user.other_first_name,
            otherLastName: data.user.other_last_name,
            otherEmail: data.user.other_email,
            freeEndsAt: data.user.free_ends_at,
            createdAt: data.user.created_at,
            updatedAt: data.user.updated_at,
          },
          status: data.status,
          orderAt: data.order_at,
          quantity: data.quantity,
          price_per_book: data.price_per_book,
          estimated_shipping_cost: data.estimated_shipping_cost,
          shippingMethod: data.shipping_method,
          shippingInfo: data.shipping_info,
          createdAt: data.created_at,
          updatedAt: data.updated_at,
        }));
      }

      dispatch(onSuccess());
      dispatch(setData(userOrders));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const changeUserOrderStatus =
  (
    id: number,
    status: string,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      await api.patch<ResponseList>(`/users-orders/${id}/status/${status}`);
      dispatch(findAllPendingUserOrders({}));

      if (handleSuccess)
        handleSuccess(
          `Status has been successfully changed ${status.toUpperCase()}.`,
        );
    } catch (error) {
      if (handleError)
        handleError(`Failed to change status to ${status.toUpperCase()}.`);
      dispatch(onSuccess());
    }
  };
