/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as MaterialTailwind from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../store/types';
import { dateTimeFormat } from '../../../utils/date-time-formatter';
import { StateStatus } from '../../../../store/helpers/initialState';
import { getBookByUser } from '../../../../store/reducers/bookReducer';
import { UserTableModel } from '../../../../store/reducers/tables/usersTableReducer';
import { findAllBookReadersByUser } from '../../../../store/reducers/bookReadersReducer';
import { findAllContributorsByUser } from '../../../../store/reducers/usersContributorsReducer';
import { PDFRepository } from '../../../../repositories/pdfRepository';
import { forceDeleteUser } from '../../../../store/reducers/usersReducer';

type UserDetailsDialogProps = {
  open: boolean;
  onClose?: () => void;
  onDelete: () => void;
  user: UserTableModel;
};

const { Button, Dialog, DialogBody, DialogFooter, DialogHeader } =
  MaterialTailwind as any;

export function UserDetailsDialog({
  open,
  onClose,
  onDelete,
  user,
}: UserDetailsDialogProps) {
  const dispatch: any = useDispatch();
  const usersContributors = useSelector(
    (state: RootState) => state.usersContributors,
  );
  const bookReaders = useSelector((state: RootState) => state.bookReaders);
  const book = useSelector((state: RootState) => state.book);
  const [pdfRepository] = useState<PDFRepository>(new PDFRepository());

  useEffect(() => {
    if (open && user?.userId) {
      dispatch(getBookByUser(user?.userId));
      dispatch(findAllBookReadersByUser({ userId: user?.userId }));
      dispatch(findAllContributorsByUser({ userId: user?.userId }));
    }
  }, [open]);

  const downloadBookPDF = async (bookId?: string) => {
    if (!bookId) return alert('User Book Link not found');

    try {
      const blob = await pdfRepository.getBookPDF(bookId);

      // open ṕdf in a new tab
      const url = window.URL.createObjectURL(blob);
      return window.open(url);
    } catch (error: any) {
      return alert("Couldn't generate PDF");
    }
  };

  const handleDeleteUser = async (userId: string) => {
    onClose!();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this! \nThis action will erase all the data from this user and if they have an active premium subscription, their subscription will be revoked.",
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(forceDeleteUser(userId));

        onDelete();
      }
    });
  };

  return (
    <Dialog className="-z-10" size="lg" open={open} handler={() => {}}>
      <div className="flex justify-between">
        <DialogHeader>User details</DialogHeader>
        <button
          type="button"
          onClick={() => {
            handleDeleteUser(user.userId);
          }}
          className="m-4 text-base text-white bg-red-900 hover:bg-red-700 p-2 rounded-lg cursor-pointer"
        >
          Delete user
        </button>
      </div>
      <DialogBody divider className="overflow-auto max-h-[70vh]">
        <table className="w-full text-left">
          <tbody>
            <tr>
              <th>First name</th>
              <td>{user?.firstName}</td>
            </tr>
            <tr>
              <th>Last name</th>
              <td>{user?.lastName}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{user?.email}</td>
            </tr>
            <tr>
              <th>Activated plan</th>
              <td>{user?.activatedPlan ?? 'No Plan'}</td>
            </tr>
            {user?.activatedPlan && (
              <tr>
                <th>Plan expire date</th>
                <td>
                  {user.planExpireDate
                    ? new Date(user.planExpireDate).toString()
                    : ''}
                </td>
              </tr>
            )}
            <tr>
              <th>Registration date</th>
              <td>{dateTimeFormat(moment(user?.registrationDate).toDate())}</td>
            </tr>
            <tr>
              <th>Last login date</th>
              <td>
                {user?.lastLoginDate
                  ? dateTimeFormat(moment(user?.lastLoginDate).toDate())
                  : '---'}
              </td>
            </tr>
            <tr>
              <th className="flex">List of contributors</th>
              <td>
                {(usersContributors.status === StateStatus.LOADING &&
                  'Loading...') ||
                  (usersContributors.data.length === 0 && 'No contributors') ||
                  (usersContributors.data.length >= 0 &&
                    usersContributors.data.map((contributor) => (
                      <li>{contributor.email}</li>
                    )))}
              </td>
            </tr>
            <tr>
              <th>List of invited people to read</th>
              <td>
                {(bookReaders.status === StateStatus.LOADING && 'Loading...') ||
                  (bookReaders.data.length === 0 && 'No readers') ||
                  (bookReaders.data.length >= 0 &&
                    bookReaders.data.map((reader) => <li>{reader.name}</li>))}
              </td>
            </tr>
            <tr>
              <th>Payment history</th>
              <td>(payment history)</td>
            </tr>
            <tr>
              <th>Users book link</th>
              <td>
                {(book.status === StateStatus.LOADING && 'Loading') ||
                  (book.data === null && 'No book link founded') ||
                  (book.data !== null && book.status !== StateStatus.LOADING && (
                    <button
                      onClick={() => downloadBookPDF(book.data?.id)}
                      type="button"
                      className="text-primary-500 hover:text-primary-600 border-none focus:outline-none"
                    >
                      Open in a new tab
                    </button>
                  ))}
              </td>
            </tr>
            <tr>
              <th className="flex">His book cover image</th>
              <td>
                {(book.status === StateStatus.LOADING && 'Loading') ||
                  (book.data === null && 'No book cover founded') ||
                  (book.data !== null &&
                    book.status !== StateStatus.LOADING && (
                      <img src={book?.data.image} alt={book?.data.title} />
                    ))}
              </td>
            </tr>
          </tbody>
        </table>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" color="teal" onClick={onClose} className="mr-1">
          <span>OK</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
