/* eslint-disable no-use-before-define */
import { createSlice } from '@reduxjs/toolkit';

import { api } from '../../app/config/api';
// import { api } from './authReducer';
import { AppDispatch } from '../types';
import { ResponseList, ResponseOne } from '../helpers/axiosResponse';
import { ErrorMessage } from '../../app/constants/error_messages';
import { InitialState, StateStatus } from '../helpers/initialState';
import { ChapterModel } from './chaptersReducer';

export type QuestionModel = {
  id: number;
  name: string;
  order: number;
  can_suggest?: boolean;
  chapter?: ChapterModel;
};

const initialState: InitialState<QuestionModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export type questionBody = {
  name: string;
  priority: number;
  chapter: number;
  can_suggest: boolean;
  order?: number;
};

export const createQuestion =
  (
    body: questionBody,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.post<ResponseList>('/chapters-questions', body);
      dispatch(onSuccess());
      if (handleSuccess)
        handleSuccess('Question has been successfully created.');
    } catch (error) {
      if (handleError) handleError('Failed to create question.');
      dispatch(onSuccess());
    }
  };

export const findAllQuestions = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(onLoading());
    const response = await api.get<ResponseList>('/chapters-questions');
    const responseData = response.data.body.data;

    const questions: QuestionModel[] = responseData.result.map<QuestionModel>(
      (data) => ({
        id: data.id,
        name: data.name,
        order: data.order,
        chapter: data.chapter,
        can_suggest: data.can_suggest,
      }),
    );

    dispatch(onSuccess());
    dispatch(setData(questions));
  } catch (error) {
    dispatch(onError(ErrorMessage.UNEXPECTED));
  }
};

export const findOneQuestions =
  (id: number) => async (dispatch: AppDispatch) => {
    dispatch(onLoading());

    try {
      const response = await api.get<ResponseOne>(`/chapters-questions/${id}`);
      const responseData = response.data.body.data;

      const question: QuestionModel = {
        id: responseData.id,
        name: responseData.name,
        order: responseData.priority,
      };

      dispatch(onSuccess());
      dispatch(setData(question));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };

export const updateQuestion =
  (
    id: number,
    body: questionBody,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.put<ResponseList>(`/chapters-questions/${id}`, body);

      dispatch(onSuccess());
      if (handleSuccess)
        handleSuccess('Question has been successfully updated.');
    } catch (error) {
      if (handleError) handleError('Failed to update question.');
      dispatch(onSuccess());
    }
  };

export const deleteQuestion =
  (
    id: number,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());
      await api.delete<ResponseList>(`/chapters-questions/${id}`);

      dispatch(findAllQuestions());
      dispatch(onSuccess());
      if (handleSuccess)
        handleSuccess('Question has been successfully deleted.');
    } catch (error) {
      if (handleError) handleError('Failed to delete question.');
      dispatch(onSuccess());
    }
  };

export const reorderQuestion =
  (
    id: number,
    newPosition: number,
    handleSuccess?: (message: string) => void,
    handleError?: (message: string) => void,
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // dispatch(onLoading());
      await api.patch<ResponseList>(
        `/chapters-questions/${id}/reorder/${newPosition}`,
      );

      dispatch(findAllQuestions());
      dispatch(onSuccess());

      if (handleSuccess)
        handleSuccess('Topic has been successfully reordered.');
    } catch (error) {
      if (handleError) handleError('Failed to reorder topic.');
      dispatch(onSuccess());
      // dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
