/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import * as MaterialTailwind from '@material-tailwind/react';

import { IoArrowBackSharp } from 'react-icons/io5';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { RootState } from '../../../../store/types';
import {
  createQuestion,
  QuestionModel,
  updateQuestion,
} from '../../../../store/reducers/questionsReducer';
import { FormInputLoadingButton } from '../../../components/Button';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../components/toast/ShowToast';
import {
  ChapterModel,
  findAllChapters,
} from '../../../../store/reducers/chaptersReducer';

const { Input, Option, Select } = MaterialTailwind as any;

export function QuestionsForm() {
  const [id, setId] = useState<number>();

  const [name, setName] = useState('');
  const [chapterId, setChapterId] = useState(0);
  const [priority, setPriority] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAutoAttached, setIsAutoAttached] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: any = useDispatch();

  const { data: ChaptersData } = useSelector(
    (state: RootState) => state.chapters,
  );

  useEffect(() => {
    dispatch(findAllChapters());
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      const question = location.state as QuestionModel;
      setId(question.id);
      setName(question.name);
      setPriority(question.order);
      setChapterId(Number(question.chapter));
      setIsAutoAttached(question.can_suggest ?? false);
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (action === 'create') {
      await dispatch(
        createQuestion(
          {
            name,
            chapter: chapterId,
            priority: priority,
            can_suggest: isAutoAttached,
          },
          showSuccessToast,
          showErrorToast,
        ),
      );
      setLoading(false);
    } else {
      await dispatch(
        updateQuestion(
          id!,
          {
            name,
            chapter: chapterId,
            priority: priority,
            can_suggest: isAutoAttached,
          },
          showSuccessToast,
          showErrorToast,
        ),
      );
      setLoading(false);
    }

    navigate(-1);
  };

  return (
    <AnimatedPage>
      <div>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="flex flex-wrap items-center">
            <Link to="/admin/questions">
              <IoArrowBackSharp size={30} />
            </Link>
            <h2 className="ml-8">
              {action === 'create' ? 'Create' : 'Edit'} a new question
            </h2>
          </div>
          <div className="mt-4 sm:w-full md:w-[70%] xl:w-[50%]">
            <div className="block xl:flex xl:flex-row">
              <div className="xl:w-[72.5%]">
                <Input
                  label="Name"
                  color="teal"
                  size="lg"
                  required
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                  variant="standard"
                  className="border-primary-500 hover:border-primary-600 w-full"
                />
              </div>
            </div>
            <div className="h-4 sm:h-8 xl:w-8" />
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="auto_attached"
                checked={isAutoAttached}
                className="cursor-pointer"
                onChange={() => setIsAutoAttached(!isAutoAttached)}
              />
              <label className="text-sm cursor-pointer" htmlFor="auto_attached">
                Auto attached
              </label>
            </div>
            <div className="h-4 sm:h-8 xl:w-8" />
            <div className="w-[34%]">
              <Input
                label="Priority"
                color="teal"
                type="number"
                variant="standard"
                value={priority}
                required
                size="lg"
                className="border-primary-500 hover:border-primary-600 w-full"
                onChange={(e: any) => {
                  setPriority(+e.target.value);
                }}
              />
            </div>
            <div className="w-72 mt-6">
              <Select
                variant="standard"
                label="Choose a Chapter"
                color="teal"
                value={chapterId?.toString()}
                onChange={(value: any) => setChapterId(Number(value))}
              >
                {ChaptersData &&
                  ChaptersData.map((item: ChapterModel) => (
                    <Option key={item.id.toString()} value={item.id.toString()}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>

          <div className="h-8" />
          <FormInputLoadingButton
            title={action === 'create' ? 'Create' : 'Edit'}
            titleWhenLoading={action === 'create' ? 'Creating' : 'Editing'}
            isLoading={loading}
          />
        </form>
      </div>
    </AnimatedPage>
  );
}
