import React from 'react';
import { GrFormClose } from 'react-icons/gr';

interface IFilter {
  label: string;
  filterText: string;
  onFilter: (e: any) => void;
  onClear: () => void;
}

export const FilterComponent = ({
  label,
  filterText,
  onFilter,
  onClear,
}: IFilter) => (
  <div className="relative w-64">
    <label htmlFor={label} className="flex flex-col text-gray-600 text-sm">
      {label}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M4 6h16M4 12h8m-8 6h16" />
          </svg>
        </div>
        <input
          id={label}
          type="text"
          onChange={onFilter}
          value={filterText}
          placeholder="Search Input"
          className="bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300 rounded-lg py-2 pl-10 pr-8 block w-full appearance-none leading-normal"
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <GrFormClose onClick={onClear} className="cursor-pointer" />
        </div>
      </div>
    </label>
  </div>
);
