/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { api } from '../app/config/api';

export type GeneralDTO = {
  welcomeVideoUrl: string;
};

export class GeneralRepository {
  public async find(): Promise<GeneralDTO> {
    const response = await api.get('/general');
    const rawData: any = response.data.body.data;

    const generalDTO: GeneralDTO = {
      welcomeVideoUrl: rawData.welcomeVideoUrl,
    };

    return generalDTO;
  }

  public async create(generalDTO: GeneralDTO): Promise<void> {
    const body = {
      welcomeVideoUrl: generalDTO.welcomeVideoUrl,
    };

    await api.post('/general', body);
  }
}
