export enum StateStatus {
  INITIAL,
  LOADING,
  SUCCEED,
  FAILED,
}

export type InitialState<T> = {
  status: StateStatus;
  data: T;
  errorMessage: string;
};
