/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { maskToUSCurrency } from '../../../utils/currency-formater';
import { dateTimeFormat } from '../../../utils/date-time-formatter';

export function makeFinancesTableColumns() {
  return [
    {
      name: 'FIRST NAME',
      sortable: true,
      selector: (row: any) => row.firstName,
    },
    {
      name: 'LAST NAME',
      sortable: true,
      selector: (row: any) => row.lastName,
    },
    {
      name: 'EMAIL',
      sortable: true,
      selector: (row: any) => row.email,
    },
    {
      name: 'PURCHASED AT',
      sortable: true,
      selector: (row: any) => row.purchasedAt,
      format: (row: any) => dateTimeFormat(row.purchasedAt),
    },
    {
      name: 'PLAN',
      sortable: true,
      selector: (row: any) => row.plan,
    },
    {
      name: 'PRICE',
      sortable: true,
      selector: (row: any) => row.price,
      format: (row: any) => maskToUSCurrency(row.price / 100),
    },
  ];
}