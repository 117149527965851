/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import * as MaterialTailwind from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { useNavigate } from 'react-router-dom';
import { Modal } from '../../../components/Modal';
import { RootState } from '../../../../store/types';
import { LinkButton } from '../../../components/Button';
import { makePlansTableColumns } from './PlansTableColumns';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { customStyles } from '../../../components/DataTableStyle';
import { StateStatus } from '../../../../store/helpers/initialState';
import {
  deletePlan,
  findAllPlans,
  PlanModel,
} from '../../../../store/reducers/plansReducer';

const { Chip } = MaterialTailwind as any;

export function Plans() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [removeItemID, setRemoveItemID] = useState<number>();

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state.plans);
  const plans = useSelector((state: RootState) => state.plans.data);
  const normalizedPlans = useSelector(
    (state: RootState) => state.plans.normalizedData,
  );

  useEffect(() => {
    dispatch(findAllPlans());
  }, []);

  const onRemoveItem = () => {
    if (removeItemID !== undefined) dispatch(deletePlan(removeItemID));
    setModalOpen(false);
  };

  return (
    <AnimatedPage>
      <>
        <div className="border rounded-md">
          <Modal
            isOpen={isModalOpen}
            setOpen={setModalOpen}
            onDone={onRemoveItem}
            title="Are you sure you want to delete it?"
          >
            <p className="pt-4">The data will be permanently deleted.</p>
            <p>This action is irreversible.</p>
          </Modal>
        </div>
        <DataTable
          title={
            <>
              <div className="h-4" />
              <p>Plans</p>
              {state.status === StateStatus.LOADING ? (
                <div />
              ) : (
                <CSVLink
                  data={normalizedPlans}
                  filename={`plans-${new Date().toISOString()}.csv`}
                  separator=";"
                >
                  <Chip value="download table" color="indigo" />
                </CSVLink>
              )}
              <div className="h-1" />
            </>
          }
          columns={makePlansTableColumns({
            onEdit: (plan: PlanModel) =>
              navigate('/admin/plans/form/edit}', {
                state: {
                  id: plan.id,
                  name: plan.name,
                  price: plan.price,
                  itemList: plan.itemList,
                },
              }),
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onDelete: (id) => {
              setRemoveItemID(id);
              setModalOpen(true);
            },
          })}
          data={plans}
          pagination
          responsive
          highlightOnHover
          progressPending={state.status === StateStatus.LOADING}
          striped
          actions={
            <LinkButton text="Create plan" route="/admin/plans/form/create" />
          }
          customStyles={customStyles}
        />
      </>
    </AnimatedPage>
  );
}
