/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import * as dotenv from 'dotenv';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import * as MaterialTailwind from '@material-tailwind/react';
import { CookiesProvider } from 'react-cookie';
import { store } from './store';
import { AppRouter } from './app/routes/AppRouter';

const { ThemeProvider } = MaterialTailwind as any;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <CookiesProvider>
        <Provider store={store}>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </Provider>
      </CookiesProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
