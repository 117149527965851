/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '../../types';
import { api } from '../../../app/config/api';
import { ErrorMessage } from '../../../app/constants/error_messages';
import { InitialState, StateStatus } from '../../helpers/initialState';

export type ReadInvitesGraphModel = {
  x: string;
  y?: number;
};

const initialState: InitialState<ReadInvitesGraphModel[]> = {
  status: StateStatus.INITIAL,
  data: [],
  errorMessage: '',
};

const slice = createSlice({
  name: 'readInvitesGraph',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    onLoading: (state) => {
      state.status = StateStatus.LOADING;
    },
    onSuccess: (state) => {
      state.status = StateStatus.SUCCEED;
    },
    onError: (state, action) => {
      state.status = StateStatus.FAILED;
      state.errorMessage = action.payload;
    },
  },
});

export const { setData, onLoading, onSuccess, onError } = slice.actions;
export default slice.reducer;

// REPOSITORY //

export type ReadInvitesFilter = {
  days?: string;
  fromDate?: Date;
  toDate?: Date;
};

export const findAllReadInvitesGraph =
  ({ days = '', fromDate, toDate }: ReadInvitesFilter) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onLoading());

      const toDateString = toDate?.toISOString()
        ? `toDate=${toDate?.toISOString()}`
        : '';

      const fromDateString = fromDate?.toISOString()
        ? `fromDate=${fromDate?.toISOString()}`
        : '';

      const response = await api.get(
        `/users/graph/read-inviters?days=${days}&${fromDateString}&${toDateString}`,
      );

      const responseData: any[] = response.data.body.data;

      const readInvites: ReadInvitesGraphModel[] =
        responseData.map<ReadInvitesGraphModel>((data) => ({
          x: new Date(
            new Date(data.date).toISOString().replace('Z', ''),
          ).toLocaleString('en-us', { month: '2-digit', day: '2-digit' }),
          y: data.count,
        }));

      dispatch(onSuccess());
      dispatch(setData(readInvites));
    } catch (error) {
      dispatch(onError(ErrorMessage.UNEXPECTED));
    }
  };
