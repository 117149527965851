import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { AdminSidebar } from './AdminSidebar';
import { Header } from './Header';
import 'react-toastify/dist/ReactToastify.css';

interface ILayout {
  page: JSX.Element;
}

interface IBaseLayout extends ILayout {
  location: { pathname: string };
}

function Toast() {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={1}
      theme="colored"
    />
  );
}

function Layout({ page }: ILayout) {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <main className="grow py-10 px-5 sm:p-10">{page}</main>
      <Toast />
    </div>
  );
}

function AdminLayout({ page }: ILayout) {
  return (
    <div className="flex h-screen">
      <AdminSidebar />

      <div className="flex flex-col h-screen w-full pl-10 sm:pl-40">
        <main className="grow py-10 px-5 sm:p-10">{page}</main>
      </div>

      <Toast />
    </div>
  );
}

export function BaseLayout({ page, location }: IBaseLayout): JSX.Element {
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    setAdmin(location.pathname.startsWith('/admin'));
  }, [location]);

  return isAdmin ? <AdminLayout page={page} /> : <Layout page={page} />;
}
