/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import {
  ChapterModel,
  reorderChapter,
} from '../../../../store/reducers/chaptersReducer';

export type ChaptersTableColumns = {
  onEdit: (chapter: ChapterModel) => void;
  onDelete: (id: number) => void;
  length: number;
  isReorder: boolean;
  setLoading: (loading: boolean) => void;
};

export function makeChaptersTableColumns({
  onEdit,
  onDelete,
  length,
  isReorder,
  setLoading,
}: ChaptersTableColumns) {
  const dispatch: any = useDispatch();

  return [
    isReorder
      ? {
          name: 'REORDER',
          sortable: true,
          width: '200px',
          selector: (row: any) => row.action,
          cell: (row: any, index: any) => (
            <div className="flex">
              {index < 1 ? null : (
                <>
                  <span className="hover:bg-blue-gray-100 rounded-full p-2">
                    <AiOutlineArrowUp
                      size={20}
                      cursor="pointer"
                      onClick={() => {
                        dispatch(reorderChapter(row.id, index));
                      }}
                    />
                  </span>
                  <div className="w-2" />
                </>
              )}
              {index > length - 2 ? null : (
                <>
                  <span className="hover:bg-blue-gray-100 rounded-full p-2">
                    <AiOutlineArrowDown
                      size={20}
                      cursor="pointer"
                      onClick={async () => {
                        setLoading(true);
                        await dispatch(reorderChapter(row.id, index + 2));
                        setLoading(false);
                      }}
                    />
                  </span>
                  <div className="w-2" />
                </>
              )}
            </div>
          ),
        }
      : { name: '', width: '0px' },
    {
      name: 'ID',
      sortable: false,
      width: '5rem',
      selector: (row: any, index: any) => row.id /* index + 1 */,
    },
    {
      name: 'NAME',
      sortable: true,
      // width: '120px',
      wrap: true,
      selector: (row: any) => row.name,
    },
    {
      name: 'ORDER',
      // width: '100px',
      selector: (row: any) => row.order,
      sortable: true,
      reorder: true,
    },
    {
      name: 'ACTIONS',
      wrap: true,
      width: '150px',
      selector: (row: any) => row.action,
      cell: (row: any) => (
        <div className="flex">
          <button
            className="py-2 px-3 rounded-md bg-btnEdit hover:bg-btnEditOnHover  text-xs text-white font-bold"
            onClick={() =>
              onEdit({
                id: row.id,
                name: row.name,
                order: row.order,
              })
            }
            id={row.action}
          >
            Edit
          </button>
          <div className="w-2" />
          <button
            className="py-2 px-3 rounded-md bg-btnDelete hover:bg-btnDeleteOnHover text-xs text-white font-bold"
            onClick={() => {
              onDelete(row.id);
            }}
            id={row.action}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
}
