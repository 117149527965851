import React from 'react';

export type InputTextField = {
  label: string;
  value: string;
  maxLength?: number;
  placeholder?: string;
  isRequired?: boolean;
  isPassword?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function InputTextField({
  label,
  value,
  isPassword = false,
  maxLength = 255,
  placeholder = '',
  isRequired,
  onChange,
}: InputTextField) {
  return (
    <label htmlFor={label} className="flex flex-col text-gray-600 text-sm">
      {label}
      <input
        id={label}
        type={isPassword ? 'password' : 'text'}
        required={isRequired}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        value={value}
        autoComplete="on"
        className={`mt-1 text-sm border-2 h-[38px] ${
          value.trim() === '' ? 'border-gray-300' : 'border-primary-500'
        } text-black-text rounded-md hover:border-primary-500 focus:outline-none focus:ring-primary-500 focus:border-primary-500 block p-2.5`}
      />
    </label>
  );
}
