/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { IoArrowBackSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import * as MaterialTailwind from '@material-tailwind/react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { AnimatedPage } from '../../../components/AnimatedPage';
import { FormInputLoadingButton } from '../../../components/Button';
import {
  createFaq,
  FaqModel,
  updateFaq,
} from '../../../../store/reducers/faqReducer';
import { RootState } from '../../../../store/types';
import {
  CategoryModel,
  findAllCategory,
} from '../../../../store/reducers/categoryReducer';

const { Input, Option, Select } = MaterialTailwind as any;
export function FaqForm() {
  const [id, setId] = useState<number>();
  const [categoryId, setCategoryId] = useState<number>();
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [mediaLink, setMediaLink] = useState('');
  const [mediaLinkType, setMediaLinkType] = useState(2);

  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams();
  const dispatch: any = useDispatch();

  const { data: CategoryData } = useSelector(
    (state: RootState) => state.category,
  );

  useEffect(() => {
    dispatch(findAllCategory());
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      const faq = location.state as FaqModel;
      setId(faq.id);
      setQuestion(faq.question);
      setAnswer(faq.answer);
      setMediaLink(faq.mediaLink);
      setMediaLinkType(faq.mediaLinkType);
      setCategoryId(Number(faq.category));
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const mediaType = mediaLinkType === 2 ? null : mediaLinkType;

    if (action === 'create') {
      await dispatch(
        createFaq({
          category: categoryId!,
          question,
          answer,
          mediaLink,
          mediaLinkType: mediaType,
        }),
      );
    } else {
      await dispatch(
        updateFaq(id!, {
          category: categoryId!,
          question,
          answer,
          mediaLink,
          mediaLinkType: mediaType,
        }),
      );
    }

    navigate(-1);
  };

  return (
    <AnimatedPage>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="flex flex-wrap items-center">
          <Link
            to="/admin/faq"
            // className="w-fit flex space-x-2 items-center justify-between h-9 px-4 bg-primary-500 rounded-full hover:bg-primary-600 text-white text-h4"
          >
            <IoArrowBackSharp size={30} />
          </Link>
          <h2 className="ml-8">
            {action === 'create' ? 'Create a new FAQ' : 'Edit your FAQ'}
          </h2>
        </div>
        <div className="mt-4 sm:w-full md:w-[70%] xl:w-[50%]">
          <div className="block xl:flex xl:flex-row">
            <div className="xl:w-full">
              <Input
                label="Question"
                color="teal"
                size="lg"
                variant="standard"
                value={question}
                required
                className="border-primary-500 hover:border-primary-600 w-full"
                onChange={(e: any) => setQuestion(e.target.value)}
              />
            </div>
          </div>
          <div className="h-4 sm:h-4 xl:h-5 xl:w-8" />
          <div className="w-[100%]">
            <Input
              label="Answer"
              color="teal"
              type="text"
              variant="standard"
              value={answer}
              required
              size="lg"
              className="border-primary-500 hover:border-primary-600 w-full"
              onChange={(e: any) => setAnswer(e.target.value)}
            />
          </div>
          <div className="h-4 sm:h-4 xl:h-5 xl:w-8" />
          <div className="flex flex-wrap justify-between w-full">
            <div className="w-[100%] 2xl:w-[70%] ">
              <Input
                label="Media link"
                color="teal"
                type="text"
                variant="standard"
                value={mediaLink}
                size="lg"
                className="border-primary-500 hover:border-primary-600 w-full"
                onChange={(e: any) => setMediaLink(e.target.value)}
              />
            </div>
            <div className="w-[100%] 2xl:w-[25%] mt-5 2xl:mt-1">
              <Select
                variant="standard"
                label="Choose a media type"
                color="teal"
                value={mediaLinkType?.toString()}
                onChange={(value: any) => setMediaLinkType(Number(value))}
                defaultValue="2"
              >
                <Option value="2">Choose a option</Option>
                <Option value="0">Image</Option>
                <Option value="1">Video</Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="h-4 sm:h-4 xl:h-5 xl:w-8" />
        <div className="w-[100%] md:w-[70%] xl:w-[50%] mt-3">
          <Select
            variant="standard"
            label="Choose a category"
            color="teal"
            value={categoryId?.toString()}
            onChange={(value: any) => setCategoryId(Number(value))}
          >
            {CategoryData &&
              CategoryData.map((item: CategoryModel) => (
                <Option key={item.id.toString()} value={item.id.toString()}>
                  {item.title}
                </Option>
              ))}
          </Select>
        </div>
        <div className="h-8" />
        <FormInputLoadingButton
          title={action === 'create' ? 'Create' : 'Edit'}
          titleWhenLoading="Creating"
          isLoading={false}
        />
      </form>
    </AnimatedPage>
  );
}
