/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import * as MaterialTailwind from '@material-tailwind/react';

import { StateStatus } from '../../../../store/helpers/initialState';
import {
  ChapterModel,
  deleteChapter,
  findAllChapters,
} from '../../../../store/reducers/chaptersReducer';
import { Modal } from '../../../components/Modal';
import { RootState } from '../../../../store/types';
import { LinkButton } from '../../../components/Button';
import { AnimatedPage } from '../../../components/AnimatedPage';
import { customStyles } from '../../../components/DataTableStyle';
import { makeChaptersTableColumns } from './ChaptersTableColumns';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../components/toast/ShowToast';
import { LoaderSVG } from '../../../components/LoaderSVG';
import { FilterComponent } from '../../../components/FilterComponent';

const { Checkbox, Chip } = MaterialTailwind as any;
export function Chapters() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isReorder, setReorder] = useState(false);
  const [removeItemID, setRemoveItemID] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [filterByName, setFilterByName] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const state = useSelector((state: RootState) => state.chapters);

  const fetchChapters = useCallback(async () => {
    await dispatch(findAllChapters());
  }, [dispatch]);

  useEffect(() => {
    fetchChapters();
  }, [fetchChapters]);

  const onRemoveItem = async () => {
    if (removeItemID !== undefined)
      await dispatch(
        deleteChapter(removeItemID, showSuccessToast, showErrorToast),
      );
    setModalOpen(false);
  };

  const filteredItems = state.data.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterByName.toLowerCase()),
  );

  const handleClear = () => {
    if (filterByName) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterByName('');
    }
  };

  return (
    <AnimatedPage>
      <>
        <div className="border rounded-md">
          <Modal
            isOpen={isModalOpen}
            setOpen={setModalOpen}
            onDone={onRemoveItem}
            title="Are you sure you want to delete it?"
          >
            <p className="pt-4">
              The data will be permanently deleted. All questions linked to this
              chapter will be deleted as well.
            </p>
            <p>This action is irreversible.</p>
          </Modal>
        </div>
        <DataTable
          title={
            <>
              <div className="flex flex-wrap items-center">
                <div className="h-4" />
                <p>Chapters</p>
                <div className="w-3" />
                {loading || state.status === StateStatus.LOADING ? (
                  <LoaderSVG />
                ) : null}
              </div>
              {state.status === StateStatus.LOADING ? (
                <div />
              ) : (
                <CSVLink
                  data={state.data}
                  filename={`chapters-${new Date().toISOString()}.csv`}
                >
                  <Chip value="download table" color="indigo" />
                </CSVLink>
              )}
              <div className="h-1" />
            </>
          }
          columns={makeChaptersTableColumns({
            onEdit: (chapter: ChapterModel) =>
              navigate('/admin/chapters/form/edit}', {
                state: {
                  id: chapter.id,
                  name: chapter.name,
                  order: chapter.order,
                },
              }),
            onDelete: (id) => {
              setRemoveItemID(id);
              setModalOpen(true);
            },
            length: state.data.length,
            isReorder,
            setLoading,
          })}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          responsive
          highlightOnHover
          striped
          subHeader
          subHeaderComponent={
            state.data.length === 0 ? null : (
              <div className="flex items-center">
                <FilterComponent
                  label="Filter by name"
                  onFilter={(e) => setFilterByName(e.target.value)}
                  onClear={handleClear}
                  filterText={filterByName}
                />
                <div className="w-[3rem] " />
                <Checkbox
                  color="teal"
                  label="Reorder"
                  checked={isReorder}
                  onChange={() => {
                    setReorder(!isReorder);
                  }}
                />
              </div>
            )
          }
          progressPending={state.status === StateStatus.LOADING}
          actions={
            <div className="flex">
              <LinkButton
                text="Create chapter"
                route="/admin/chapters/form/create"
              />
            </div>
          }
          customStyles={customStyles}
        />
      </>
    </AnimatedPage>
  );
}
