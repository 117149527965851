import React from 'react';

import { AnimatedPage } from '../../../components/AnimatedPage';
import { BookOrdersChart } from './components/BookOrdersChart';
import { PlanRankingChart } from './components/PlanRankingChart';
import { PlansActivationChart } from './components/PlansActivationChart';
import { QuestionAnsweringChart } from './components/QuestionAnsweringChart';
import { ReadInvitesChart } from './components/ReadInvitesChart';
import { SignInsChart } from './components/SignInsChart';
import { SignUpsChart } from './components/SignUpsChart';
import { TopContributionsInviterChart } from './components/TopContributionsInviterChart';
import { ContributionsChart } from './components/ContributionsChart';
import { PendingBookOrdersTable } from './components/PendingBookOrdersTable';
import { TopReadingInviterChart } from './components/TopReadInviterChart';

export const Dashboard = () => (
  <AnimatedPage>
    <div className="space-y-8">
      <div className="block xl:flex xl:flex-row">
        <SignInsChart />
        <SignUpsChart />
      </div>
      <div className="block xl:flex xl:flex-row">
        <ContributionsChart />
        <TopContributionsInviterChart />
      </div>
      <div className="block xl:flex xl:flex-row">
        <ReadInvitesChart />
        <TopReadingInviterChart />
      </div>
      <div className="block xl:flex xl:flex-row">
        <BookOrdersChart />
        <QuestionAnsweringChart />
      </div>
      <div className="block xl:flex xl:flex-row">
        <PlansActivationChart />
        <div className="w-full lg:w-[70%]" />
        {/* <PlanRankingChart /> */}
      </div>
      {/* <PendingBookOrdersTable /> */}
    </div>
  </AnimatedPage>
);
